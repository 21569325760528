/*breakpoint mixin start here*/
/*breakpoint mixin end here*/
/*
Author         : G-axon
Template Name  : Wieldy Antd React - Admin Template
Version        : 1.0
*/
/*=============================================================
    Table of Contents:
    =============================
    A. Globals
        01. Custom Animation
        02. Colors Class

    B. Base
        01. Base Styles
        02. Row Col Styles
        03. Margin Padding Styles
        04. Typography Styles
        05. Flex Styles

    C. Layouts
        01. Ant Layout Style
        02. Header Style
        03. Sidebar Style
        04. Navbar(Ant Menu) Style
        05. Footer Styles
        06. Customizer Style
        07. Drawer Style

    D. UI Components
        01. Alert Styles
        02. Avatar Styles
        03. BackTop Styles
        04. Badges Styles
        05. Button Styles
        06. Cards Styles
        07. Cascader Styles
        08. Chart Styles
        09. Form Styles
        10. Icon Styles
        11. Input Styles
        12. List Styles
        13. Loader Styles
        14. Pagination Styles
        15. Pickers Styles
        16. Progress Styles
        17. Slider Styles
        18. Steps Styles
        19. Tables Styles
        20. Tabs Styles
        21. Time Lines Style

    E. Apps
        01. Apps Style
        02. Mails Apps Styles
        03. Chat Apps Styles
        04. Contact Apps Styles
        05. Calendar Apps Style
        06. Profile App Style
        07. Wall App Style

    F. Pages
        01. Callout Styles
        02. E-commerce Styles
        03. Pricing Tables Styles
        04. Login Styles
        05. Dashboard Styles
        06. Error Styles
        07. Editor Styles
        08. Testimonial Styles
        09. Algolia Styles

  =============================================================*/
/*
@import '../../node_modules/antd/dist/antd.less';
*/
@import '../../node_modules/cropperjs/dist/cropper.css';
/*Typography Styles*/
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #202a3f;
  font-weight: 500;
  line-height: 1.2;
  margin: 0 0 0.5em 0;
}
h1,
.h1 {
  font-size: 22px;
}
.framed-layout h1,
.framed-layout .h1 {
  font-size: 20px;
}
@media screen and (max-width: 767px) {
  .framed-layout h1,
  .framed-layout .h1 {
    font-size: 18px;
  }
}
.boxed-layout h1,
.boxed-layout .h1 {
  font-size: 18px;
}
@media screen and (max-width: 767px) {
  .boxed-layout h1,
  .boxed-layout .h1 {
    font-size: 16px;
  }
}
@media screen and (min-width: 1600px) {
  h1,
  .h1 {
    font-size: 28px;
  }
  .boxed-layout h1,
  .boxed-layout .h1 {
    font-size: 18px;
  }
}
h2,
.h2 {
  font-size: 20px;
}
h3,
.h3 {
  font-size: 18px;
}
h4,
.h4 {
  font-size: 16px;
}
h5,
.h5 {
  font-size: 14px;
}
h6,
.h6 {
  font-size: 12px;
}
.gx-h1-lg {
  font-size: 28px;
}
.gx-text-strikethrough {
  text-decoration: line-through !important;
}
.gx-text-underline {
  text-decoration: underline !important;
}
/*Font size Classes*/
/*8px*/
.gx-fs-xxs {
  font-size: 8px;
}
/*10px*/
.gx-fs-xs {
  font-size: 10px;
}
/*11px*/
.gx-fs-11 {
  font-size: 11px;
}
/*12px*/
.gx-fs-sm {
  font-size: 12px;
  line-height: 1.5;
}
/*14px*/
.gx-fs-md {
  font-size: 14px;
  line-height: 1;
}
/*16px*/
.gx-fs-lg {
  font-size: 16px;
  line-height: 1;
}
/*20px*/
.gx-fs-xl {
  font-size: 20px;
  line-height: 1;
}
/*24px*/
.gx-fs-xxl {
  font-size: 24px;
  line-height: 1.3;
}
@media screen and (max-width: 1999px) {
  .gx-fs-xxl {
    font-size: 20px;
  }
}
/*28px*/
.gx-fs-2xl {
  font-size: 28px !important;
  line-height: 1;
}
@media screen and (max-width: 1199px) {
  .gx-fs-2xl {
    font-size: 20px !important;
  }
}
/*30px*/
.gx-fs-xxxl {
  font-size: 30px;
  line-height: 1;
}
@media screen and (max-width: 1366px) {
  .gx-fs-xxxl {
    font-size: 28px;
  }
}
@media screen and (max-width: 1999px) {
  .gx-fs-xxxl {
    font-size: 26px;
  }
}
@media screen and (max-width: 991px) {
  .gx-fs-xxxl {
    font-size: 24px;
  }
}
/*40px*/
.gx-fs-xlxl {
  font-size: 40px;
  line-height: 1;
}
@media screen and (max-width: 1999px) {
  .gx-fs-xlxl {
    font-size: 32px;
  }
}
@media screen and (max-width: 991px) {
  .gx-fs-xlxl {
    font-size: 28px;
  }
}
/*50px*/
.gx-fs-icon-lg {
  font-size: 50px;
  line-height: 1;
}
@media screen and (max-width: 1366px) {
  .gx-fs-icon-lg {
    font-size: 38px;
  }
}
@media screen and (max-width: 575px) {
  .gx-fs-icon-lg {
    font-size: 30px;
  }
}
/*54px*/
.gx-fs-iconcard {
  font-size: 54px;
  line-height: 1;
}
@media screen and (max-width: 1366px) {
  .gx-fs-iconcard {
    font-size: 34px;
  }
}
.gx-text-lowercase {
  text-transform: lowercase;
}
.gx-text-uppercase {
  text-transform: uppercase;
}
.gx-text-capitalize {
  text-transform: capitalize;
}
.gx-font-weight-light {
  font-weight: 300;
}
.gx-font-weight-normal {
  font-weight: 400;
}
.gx-font-weight-medium {
  font-weight: 500;
}
.gx-font-weight-semi-bold {
  font-weight: 600;
}
.gx-font-weight-bold {
  font-weight: 700;
}
.gx-font-weight-heavy {
  font-weight: 900;
}
.gx-font-italic {
  font-style: italic;
}
.gx-letter-spacing-base {
  letter-spacing: 3px;
}
.gx-letter-spacing-lg {
  letter-spacing: 6px;
}
.gx-letter-spacing-xl {
  letter-spacing: 8px;
}
.gx-text-justify {
  text-align: justify !important;
}
.gx-text-nowrap {
  white-space: nowrap !important;
}
.gx-text-left {
  text-align: left !important;
}
.gx-text-right {
  text-align: right !important;
}
.gx-text-center {
  text-align: center !important;
}
.gx-text-truncate {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* Ant Layout Style */
.ant-layout {
  position: relative;
  background: #f2f4f7 !important;
  overflow-y: hidden;
}
.ant-layout > .gx-layout-content {
  overflow-x: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.full-scroll .ant-layout > .gx-layout-content {
  overflow-x: inherit;
}
.ant-layout.ant-layout-has-sider > .ant-layout {
  position: relative;
  background: #f5f5f5;
}
.framed-layout .ant-layout,
.boxed-layout .ant-layout {
  background: #f5f5f5;
}
.framed-layout .ant-layout,
.boxed-layout .ant-layout {
  background: #f5f5f5;
}
.ant-layout.gx-app-layout {
  height: 100vh;
}
.framed-layout .ant-layout.gx-app-layout {
  height: calc(100vh - 2 * 20px);
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-layout.gx-app-layout {
    height: 100vh;
  }
}
.framed-layout.full-scroll .ant-layout.gx-app-layout,
.boxed-layout.full-scroll .ant-layout.gx-app-layout {
  overflow-y: scroll;
}
.ant-layout.gx-app-layout > .ant-layout {
  height: 100vh;
}
.framed-layout .ant-layout.gx-app-layout > .ant-layout {
  height: calc(100vh - 2 * 20px);
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-layout.gx-app-layout > .ant-layout {
    height: 100vh;
  }
}
.full-scroll .ant-layout.ant-layout-has-sider > .ant-layout,
.full-scroll .ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: inherit;
}
.multiple-tenant {
  padding: 0 20px 20px;
}
.gx-main-content-wrapper {
  padding: 20px 20px;
  flex: 1;
}
@media screen and (max-width: 575px) {
  .gx-main-content-wrapper {
    padding: 20px 15px 0;
  }
}
.gx-container-wrap .gx-main-content-wrapper {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 768px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 920px;
  }
}
@media screen and (min-width: 1200px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1100px;
  }
}
@media screen and (min-width: 1367px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1200px;
  }
}
@media screen and (min-width: 1600px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1400px;
  }
}
.gx-main-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  flex: 1;
  height: 100%;
}
.gx-container {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 768px) {
  .gx-container {
    width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .gx-container {
    width: 920px;
  }
}
@media screen and (min-width: 1200px) {
  .gx-container {
    width: 1100px;
  }
}
@media screen and (min-width: 1367px) {
  .gx-container {
    width: 1200px;
  }
}
@media screen and (min-width: 1600px) {
  .gx-container {
    width: 1400px;
  }
}
/* Header Style */
.ant-layout-header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: no-wrap;
  -ms-flex-wrap: no-wrap;
  flex-wrap: no-wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  line-height: 1;
  padding: 0px 32px;
  height: 72px;
  position: relative;
  z-index: 10;
  color: #202a3f;
  font-size: 16px;
  box-shadow: 0px 0px 10px 1px #aaa;
  background: #ffffff;
}
.ant-layout-header .header .ant-menu {
  font-size: 16px;
}
.ant-layout-header .header .ant-menu a {
  color: black;
}
.ant-layout-header .header .ant-menu .ant-menu-item-selected a,
.ant-layout-header .header .ant-menu :hover a {
  color: #fc5027;
}
@media screen and (max-width: 999px) {
  .ant-layout-header {
    background: none;
    margin: 0px;
    padding: 0px;
  }
}
@media screen and (max-width: 575px) {
  .ant-layout-header {
    height: 50px;
  }
}
.ant-layout-header .ant-btn-round {
  border-radius: 82px;
  height: 32px;
  padding: 0px;
  border: none;
}
.ant-layout-header .ant-btn-round svg {
  height: 26px;
  width: 26px;
}
.ant-layout-header .ant-btn-round svg:hover,
.ant-layout-header .ant-btn-round svg:focus {
  fill: #fc5027;
}
.gx-nav-header {
  padding: 0px 32px;
  border-bottom: 4px solid rgba(255, 255, 255, 0.5);
  background-color: #fefefe;
}
.gx-nav-header .ant-menu-horizontal {
  margin-bottom: -1px;
  border-bottom-color: transparent;
}
.gx-nav-header-below {
  -webkit-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.28);
  position: relative;
  z-index: 999;
}
.ant-dropdown-menu-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-linebar {
  font-size: 20px;
}
.gx-header-horizontal-dark .gx-linebar {
  color: #ffffff;
}
.gx-header-notifications {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-header-notifications > li {
  font-size: 18px;
}
.gx-header-notifications > li:not(:last-child) {
  margin-right: 42px;
}
.gx-inside-header-horizontal .gx-header-notifications > li:not(:last-child) {
  margin-right: 20px;
}
.gx-header-horizontal-main .gx-header-notifications > li:not(:last-child),
.gx-header-horizontal-nav .gx-header-notifications > li:not(:last-child) {
  margin-right: 30px;
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main .gx-header-notifications > li:not(:last-child),
  .gx-header-horizontal-nav .gx-header-notifications > li:not(:last-child) {
    margin-right: 16px;
  }
}
@media screen and (max-width: 991px) {
  .gx-header-notifications > li:not(:last-child) {
    margin-right: 16px;
  }
}
.gx-header-notifications > li.gx-notify {
  margin-right: 22px;
}
.gx-header-horizontal-main .gx-header-notifications > li.gx-notify {
  margin-right: 30px;
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main .gx-header-notifications > li.gx-notify {
    margin-right: 16px;
  }
}
.gx-inside-header-horizontal .gx-header-notifications > li.gx-notify {
  margin-right: 20px;
}
@media screen and (max-width: 991px) {
  .gx-header-notifications > li.gx-notify {
    margin-right: 16px;
  }
}
.gx-header-notifications > li.gx-language {
  font-size: 14px;
}
@media screen and (max-width: 575px) {
  .gx-header-notifications > li.gx-language .gx-language-name,
  .gx-header-notifications > li.gx-language .icon {
    display: none;
  }
  .gx-header-notifications > li.gx-language .flag.flag-24 {
    width: 20px;
    height: 20px;
    margin-top: -4px;
  }
}
@media screen and (max-width: 575px) {
  .gx-header-notifications > li.gx-notify,
  .gx-header-notifications > li.gx-msg {
    margin-top: 2px;
  }
  .gx-header-notifications > li.gx-language {
    margin-bottom: 2px;
  }
}
.gx-header-horizontal-main .gx-header-notifications > li.gx-user-nav {
  margin-left: 50px;
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main .gx-header-notifications > li.gx-user-nav {
    margin-left: 0;
  }
}
@media screen and (max-width: 1999px) {
  .gx-inside-header-horizontal .gx-header-horizontal-main .gx-header-notifications > li.gx-user-nav {
    margin-left: 0;
  }
}
.gx-header-horizontal-main .gx-header-notifications li > span {
  color: #202a3f;
}
.gx-header-horizontal-dark .gx-header-horizontal-main .gx-header-notifications li > span {
  color: #fa8c15;
}
.gx-popover-scroll {
  height: 280px !important;
  width: 300px !important;
  margin: 0 -16px;
}
@media screen and (max-width: 575px) {
  .gx-popover-scroll {
    height: 280px !important;
    width: 200px !important;
  }
}
.gx-popover-lang-scroll {
  height: 220px !important;
  width: 160px !important;
  margin: 0 -16px;
}
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 10px;
}
.gx-popover-header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  border-bottom: solid 1px #e5e8ed;
  margin: 0 -16px;
  padding: 0 16px 12px;
}
.gx-sub-popover {
  list-style: none;
  margin: 0 16px;
  padding-left: 0;
}
.gx-sub-popover li {
  padding: 20px 0 14px;
}
.gx-popover-lang-scroll .gx-sub-popover li {
  padding: 5px 0;
}
.gx-sub-popover li:not(:last-child) {
  border-bottom: solid 1px #e5e8ed;
}
.gx-user-popover {
  margin: -12px -16px;
  padding: 7px 0;
  list-style: none;
}
.gx-user-popover li {
  cursor: pointer;
  padding: 5px 10px;
  width: 150px;
}
.gx-user-popover li:hover,
.gx-user-popover li:focus {
  background-color: #fafafa;
}
@media screen and (max-width: 575px) {
  .gx-user-popover li {
    width: 100%;
  }
}
.gx-header-horizontal {
  position: relative;
  color: #fa8c15;
  background-color: #ffffff;
}
.gx-header-horizontal-dark {
  position: relative;
  background-color: #003366;
  color: #fa8c15;
}
.gx-header-horizontal-dark:before {
  content: '';
  position: absolute;
  right: 169px;
  top: -212px;
  background-color: rgba(255, 255, 255, 0.2);
  width: 280px;
  height: 350px;
  -webkit-border-radius: 0 0 135px 135px;
  -moz-border-radius: 0 0 135px 135px;
  border-radius: 0 0 135px 135px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 1;
}
@media screen and (max-width: 1849px) {
  .gx-header-horizontal-dark:before {
    right: 110px;
  }
}
@media screen and (max-width: 1999px) {
  .gx-header-horizontal-dark:before {
    top: -184px;
    right: 80px;
    height: 300px;
  }
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-dark:before {
    top: -154px;
    right: 140px;
    height: 240px;
    width: 200px;
  }
}
@media screen and (max-width: 575px) {
  .gx-header-horizontal-dark:before {
    right: 60px;
    display: none;
  }
}
.gx-header-horizontal-dark > div {
  position: relative;
  z-index: 2;
}
.gx-header-horizontal-top {
  padding: 12px 0;
  font-size: 14px;
}
.gx-header-horizontal-top-flex,
.gx-header-horizontal-nav-flex,
.gx-header-horizontal-main-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-header-horizontal-top-left {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-right: 15px;
}
@media screen and (max-width: 767px) {
  .gx-header-horizontal-top-left {
    margin-right: 0;
    width: 100%;
  }
}
.gx-header-horizontal-main {
  display: -webkit-block;
  display: -ms-blockbox;
  display: -ms-block;
  display: block;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 25px 0 35px;
  height: auto;
  background-color: transparent;
}
@media screen and (max-width: 1999px) {
  .gx-header-horizontal-main {
    padding: 20px 0;
  }
  .gx-header-horizontal-main .gx-w-logo {
    padding: 7px 5px;
  }
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main {
    padding: 0;
  }
}
@media screen and (max-width: 575px) {
  .gx-header-horizontal-main .gx-w-logo {
    padding-right: 0;
  }
}
.gx-inside-header-horizontal .gx-header-horizontal-main {
  padding: 0;
}
.gx-header-horizontal-nav {
  background-color: #003366;
  color: #fffffd;
}
.gx-header-horizontal-dark .gx-header-horizontal-nav {
  background-color: transparent;
}
.gx-below-header-horizontal .gx-header-horizontal-nav,
.gx-above-header-horizontal .gx-header-horizontal-nav {
  background-color: #fefefe;
  color: #3a4c72;
}
.gx-inside-header-horizontal .gx-logo {
  margin-right: 25px;
}
.gx-inside-header-horizontal:before {
  top: -230px;
}
@media screen and (max-width: 1999px) {
  .gx-inside-header-horizontal:before {
    top: -190px;
  }
}
@media screen and (max-width: 991px) {
  .gx-inside-header-horizontal:before {
    top: -154px;
  }
}
.gx-above-header-horizontal:before {
  top: -165px;
}
@media screen and (max-width: 991px) {
  .gx-above-header-horizontal:before {
    top: -154px;
  }
}
.gx-login-list {
  list-style: none;
  margin-left: auto;
  margin-bottom: 0;
  padding-left: 0;
}
.gx-login-list li {
  padding: 0 10px;
  text-transform: uppercase;
  color: #fa8c15;
  font-size: 12px;
  line-height: 12px;
  display: inline-block;
  cursor: pointer;
}
.gx-login-list li:not(:first-child) {
  border-left: solid 1px #e5e8ed;
}
.gx-header-horizontal-dark .gx-login-list li:not(:first-child) {
  border: 0 none;
}
@media screen and (max-width: 767px) {
  .gx-login-list {
    display: none;
  }
}
.gx-header-search {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-left: 28px;
}
.gx-header-search .ant-select-selection,
.gx-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  background-color: #003366;
  border-color: #003366;
  color: #fffffd;
  margin-bottom: 0;
}
.gx-header-search .ant-select-selection:focus,
.gx-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:focus {
  background-color: #003366;
  border-color: #003366;
  color: #fffffd;
}
.gx-header-horizontal-dark .gx-header-search .ant-select-selection,
.gx-header-horizontal-dark .gx-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  color: #fffffd;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
  margin-left: 1px;
}
.gx-header-horizontal-dark .gx-header-search .ant-select-selection:focus,
.gx-header-horizontal-dark .gx-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:focus {
  color: #fffffd;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}
.gx-header-search .ant-select-arrow {
  color: #fffffd;
}
.gx-popover-horizantal .ant-btn {
  margin: 0 !important;
  border: 0 none;
  border-right: solid 1px #e5e8ed;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  height: 24px;
  line-height: 24px;
}
.gx-popover-horizantal .gx-popover-search-bar {
  margin: 0;
}
.gx-popover-horizantal .gx-popover-search-bar .ant-input {
  height: 24px;
  line-height: 24px;
}
@media screen and (max-width: 575px) {
  .gx-popover-horizantal {
    left: 0 !important;
    padding-top: 0;
    margin-top: -9px;
  }
  .gx-popover-horizantal .ant-popover-inner-content {
    width: 100vw;
  }
  .gx-popover-horizantal .ant-popover-arrow {
    display: none;
  }
  .gx-popover-horizantal .gx-popover-search-bar {
    width: 100%;
  }
  .gx-popover-horizantal .gx-popover-scroll,
  .gx-popover-horizantal .gx-popover-lang-scroll {
    width: 100% !important;
    margin: 0;
  }
  .gx-popover-horizantal .gx-sub-popover {
    margin: 0;
    padding-left: 5px;
  }
}
.gx-no-header-horizontal {
  padding: 14px 20px 13px 55px;
  text-align: center;
  position: relative;
}
.gx-no-header-horizontal-top {
  padding: 10px 20px;
  background-color: #d9f7be;
  color: #237804;
  border-radius: 4px;
  display: inline-block;
}
@media screen and (max-width: 575px) {
  .gx-no-header-horizontal-top {
    width: 100%;
    font-size: 12px;
  }
}
.gx-no-header-horizontal-top-center {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-no-header-horizontal .gx-linebar {
  position: absolute;
  left: 20px;
  top: 15px;
  z-index: 1;
}
@media screen and (max-width: 575px) {
  .gx-no-header-horizontal .gx-linebar {
    left: 15px;
  }
}
@media screen and (max-width: 575px) {
  .gx-no-header-horizontal {
    padding: 14px 15px 13px 60px;
  }
}
.selected-svg svg {
  fill: #fc5027;
}
h1.header-h1 {
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  margin-left: 35px;
}
.logo {
  margin-right: 0;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 70px auto;
  padding-top: 0.5rem;
  height: 55px;
}
.profileImage {
  margin: 0 10px;
  height: 35px;
}
.header-logo {
  height: 35px;
}
.todo-count {
  background-color: #fc5027;
  margin-left: 5px;
  color: #ffffff;
  line-height: 12px;
  border: none;
}
.todo-count .ant-avatar-string {
  font-size: 10px;
  font-weight: 500;
}
.header-right {
  height: 100%;
}
.header-right .ant-menu-inline,
.header-right .ant-menu-vertical,
.header-right .ant-menu-vertical-left {
  border: none;
}
.header-right .ant-popover-arrow {
  display: none;
}
.header-right .ant-popover-inner {
  padding: 6px;
}
.header-right .ant-popover-inner .ant-popover-inner-content .ant-menu-light.ant-menu-root.ant-menu-vertical {
  border: none;
}
.header-right .ant-space-align-center {
  justify-content: center;
}
.header-right .ant-popover-placement-bottom,
.header-right .ant-popover-placement-bottomLeft,
.header-right .ant-popover-placement-bottomRight {
  padding-top: 5.313709px;
}
.header-right .ant-menu-item,
.header-right .ant-menu-item-only-child {
  height: 38px;
  padding: 0px 10px;
}
.header-right .ant-menu-item.ant-menu-item-selected {
  background: none !important;
}
.header-right li.ant-menu-submenu.ant-menu-submenu-horizontal {
  border-bottom: none;
}
.header-right li.ant-menu-submenu.ant-menu-submenu-horizontal .ant-menu-title-content {
  display: none;
}
.header-right li.ant-menu-submenu.ant-menu-submenu-horizontal:hover {
  border-bottom: none;
}
.header-right .ant-menu-submenu-title {
  display: flex;
  align-items: center;
  padding: 0;
}
.header-right .ant-menu-submenu-title svg {
  margin-right: 16px;
}
.header li.ant-menu-overflow-item.ant-menu-item {
  padding: 0;
  margin: 0 20px;
  border-radius: 0;
}
/* Sidebar Style */
.gx-app-sidebar {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.ant-layout-sider {
  color: #202a3f;
  background: rgba(0, 0, 0, 0.28);
  flex: 0 0 auto !important;
  max-width: none !important;
  min-width: 10px !important;
  width: 280px !important;
  overflow: hidden;
}
@media screen and (max-width: 1366px) {
  .ant-layout-sider {
    width: 100% !important;
  }
}
@media screen and (max-width: 1000px) {
  .ant-layout-sider {
    position: absolute;
    z-index: 999;
    height: 100%;
  }
}
.ant-layout-sider-collapsed {
  width: 80px !important;
}
@media screen and (max-width: 1000px) {
  .ant-layout-sider-collapsed {
    display: none;
  }
}
.ant-layout-sider.gx-collapsed-sidebar {
  width: 0 !important;
  min-width: 0 !important;
}
.ant-layout-sider .sider-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100% - 145px);
}
.ant-layout-sider .sider-content .ant-menu {
  color: #202a3f;
}
.ant-layout-sider .sider-footer {
  justify-content: left;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 16px;
}
.ant-layout-sider .sider-footer-buttons {
  width: 100%;
  height: max-content;
  padding: 0px;
}
.ant-layout-sider .sider-footer-buttons .ant-btn {
  width: 50%;
  height: 45px;
}
.ant-layout-sider .sider-footer svg {
  fill: #676c77;
}
.ant-layout-sider .sider-footer .ant-btn {
  border-radius: 0px;
}
.ant-layout-sider .sider-footer > .ant-menu {
  background-color: #ffffff;
}
.ant-layout-sider .sider-footer .ant-menu-submenu-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media screen and (max-width: 380px) {
  .ant-layout-sider .sider-footer {
    margin-top: 210px;
  }
}
.ant-layout-sider .ant-layout-sider-trigger {
  position: absolute;
  background: #000000;
  height: 56px;
  width: 100% !important;
}
.ant-layout-sider .ant-layout-sider-trigger svg {
  margin-top: 15px;
}
.ant-layout-sider .ant-popover-inner-content {
  justify-content: center;
  display: flex;
  padding: 10px 16px 0px;
  color: #202a3f;
}
.gx-layout-sider-dark {
  background-color: #003366;
  color: #038fdd;
}
.gx-layout-sider-header {
  justify-content: center;
  height: 72px;
  position: relative;
  z-index: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
@media screen and (max-width: 575px) {
  .gx-layout-sider-header {
    height: 50px;
  }
}
@media screen and (max-width: 1000px) {
  .gx-layout-sider-header {
    margin-left: 5px;
    justify-content: space-around;
  }
}
.gx-layout-sider-header .gx-site-logo {
  display: block;
}
.gx-mini-custom-sidebar .gx-layout-sider-header .gx-site-logo {
  display: block;
}
.gx-layout-sider-header .gx-linebar {
  position: absolute;
  left: 20px;
  z-index: 1;
  top: 15px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.ant-layout-sider-collapsed .gx-layout-sider-header .gx-linebar {
  left: 20px;
}
.gx-drawer-sidebar .gx-layout-sider-header {
  padding-left: 30px;
}
.gx-drawer-sidebar-dark .gx-layout-sider-header {
  background-color: rgba(0, 0, 0, 0.3);
}
.gx-layout-sider-dark .gx-layout-sider-header {
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.gx-mini-custom-sidebar .gx-layout-sider-header {
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  background-color: transparent;
}
.gx-custom-sidebar .gx-layout-sider-header {
  padding-left: 30px;
  background-color: transparent;
}
.gx-mini-custom-sidebar.ant-layout-sider-lite .gx-layout-sider-header,
.gx-custom-sidebar.ant-layout-sider-lite .gx-layout-sider-header {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-right: 1px solid #e8e8e8;
}
.gx-sidebar-content {
  border-right: 1px solid #fc5027;
  background: #202a3f;
}
.gx-layout-sider-dark .gx-sidebar-content,
.gx-drawer-sidebar-dark .gx-sidebar-content {
  border-right: 1px solid #fc5027;
}
.gx-layout-sider-scrollbar {
  height: calc(100vh - 72px - 145px) !important;
}
.framed-layout .gx-layout-sider-scrollbar {
  height: calc(100vh - 72px - 2 * 20px - 145px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
.ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
  height: calc(100vh - 72px - 91px) !important;
}
.framed-layout .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
  height: calc(100vh - 72px - 2 * 20px - 91px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 991px) {
  .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 575px) {
  .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
    height: calc(100vh - 50px) !important;
  }
}
.gx-mini-custom-sidebar .gx-layout-sider-scrollbar {
  height: calc(100vh - 72px - 206px) !important;
}
.framed-layout .gx-mini-custom-sidebar .gx-layout-sider-scrollbar {
  height: calc(100vh - 72px - 2 * 20px - 206px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-mini-custom-sidebar .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 991px) {
  .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-layout-sider-scrollbar {
    height: calc(100vh - 50px) !important;
  }
}
@media screen and (max-width: 991px) {
  .gx-no-header-notifications + .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px - 145px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-no-header-notifications + .gx-layout-sider-scrollbar {
    height: calc(100vh - 50px - 145px) !important;
  }
}
.gx-sidebar-notifications {
  padding: 30px 10px 10px;
  margin: 0 20px 10px;
  border-bottom: solid 1px #e5e8ed;
}
.ant-layout-sider-dark .gx-sidebar-notifications,
.gx-drawer-sidebar-dark .gx-sidebar-notifications {
  border-bottom-color: #038fdd;
}
@media screen and (max-width: 991px) {
  .gx-sidebar-notifications.gx-no-header-notifications {
    display: block;
  }
}
.gx-app-nav {
  list-style: none;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin: 0 -20px;
  color: #fc5027;
}
.ant-layout-sider-dark .gx-app-nav,
.gx-drawer-sidebar-dark .gx-app-nav {
  color: #038fdd;
}
.gx-app-nav li {
  font-size: 20px;
}
.gx-app-nav li svg {
  cursor: pointer;
  fill: #fc5027;
}
.ant-layout-sider-collapsed .gx-app-nav {
  display: none;
}
.gx-mini-custom-sidebar .gx-app-nav {
  display: block;
  margin-top: 15px;
}
.gx-mini-custom-sidebar .gx-app-nav li:not(:last-child) {
  margin-bottom: 5px;
}
.gx-avatar-name {
  cursor: pointer;
}
.ant-layout-sider-collapsed .gx-avatar-name {
  display: none;
}
.ant-layout-sider-collapsed .gx-avatar-row {
  margin-bottom: 0 !important;
}
.gx-avatar-row .ant-avatar {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  position: relative;
  left: 0;
}
.ant-layout-sider-collapsed .gx-avatar-row .ant-avatar {
  margin: 0 !important;
  left: -10px;
}
.gx-menu-group .ant-menu-item-group-title {
  font-size: 16px;
  padding-top: 30px !important;
}
.gx-layouts-view .gx-pointer {
  display: block;
  margin-bottom: 15px;
}
.gx-layouts-view .gx-pointer img {
  width: 100%;
}
.gx-layouts-view .gx-pointer.active {
  box-shadow: 0 0 10px 0 #fc5027;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  display: none;
}
button.ant-btn.sider-menubar {
  display: block;
  position: absolute;
  left: 0;
  padding: 7px 7px;
  border-radius: 0px 8px 8px 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  z-index: 99999;
  top: 10px;
  width: 36px;
}
button.ant-btn.sider-menuarrow {
  background: none;
  border: none;
}
.ant-layout-sider-children {
  width: 240px;
  background: #f2f4f7;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  width: 100%;
  background: white;
  height: 45px;
}
.selected-button svg {
  fill: #fc5027;
}
/* Ant Menu Style */
.ant-menu {
  color: #202a3f;
}
.ant-menu-horizontal {
  background: none;
}
.ant-menu-item-group-title {
  color: #545454;
  font-size: 11px;
  line-height: 1.3;
  padding: 10px 30px;
}
.ant-menu-submenu-popup .ant-menu-item-group-title {
  padding-left: 50px;
}
.ant-layout-sider .ant-menu-item-group-title {
  padding-top: 40px;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  color: #fc5027;
}
.ant-menu-item:active svg:active,
.ant-menu-submenu-title:active svg:active {
  fill: #fc5027;
}
.ant-menu-item svg {
  min-width: 16px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: middle;
}
.ant-menu-item svg[class^='svg-']::before,
.ant-menu-item svg[class*=' svg-']::before {
  position: relative;
  top: 2px;
}
.ant-menu-item svg[class*=' svg-']:active {
  fill: #fc5027;
}
.ant-menu-sub .ant-menu-item svg {
  font-size: 12px;
}
.ant-menu-item > a:hover {
  color: #fc5027;
}
.ant-menu svg {
  fill: #202a3f;
}
.ant-menu svg:active {
  fill: #fc5027;
}
.ant-menu-item-divider {
  background-color: #e8e8e8;
}
.ant-menu-submenu-inline {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  fill: #fc5027;
  color: #fc5027;
}
.ant-menu-item:hover svg:hover,
.ant-menu-item-active svg:hover,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open svg:hover,
.ant-menu-submenu-active svg:hover,
.ant-menu-submenu-title:hover svg:hover {
  fill: #fc5027;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  fill: #fc5027;
}
.ant-menu-item-selected > a,
.ant-menu-item-selected svg {
  fill: #fc5027;
}
.ant-menu-item-selected > a:hover,
.ant-menu-item-selected svg:hover {
  color: #fc5027;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: #fc5027;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #e8e8e8;
}
.ant-layout-sider .ant-menu-inline,
.ant-layout-sider .ant-menu-vertical,
.ant-layout-sider .ant-menu-vertical-left,
.gx-drawer-sidebar .ant-menu-inline,
.gx-drawer-sidebar .ant-menu-vertical,
.gx-drawer-sidebar .ant-menu-vertical-left {
  border-right: 0 none;
  background: none;
}
.ant-menu-vertical-right {
  border-left: 1px solid #e8e8e8;
}
.ant-menu-item,
.ant-menu-submenu-title {
  padding: 0 20px;
}
.gx-inside-header-horizontal .ant-menu-item,
.gx-inside-header-horizontal .ant-menu-submenu-title {
  padding: 0 10px;
}
@media screen and (max-width: 1999px) {
  .gx-inside-header-horizontal .ant-menu-item,
  .gx-inside-header-horizontal .ant-menu-submenu-title {
    padding: 0 6px;
  }
}
.ant-menu > .ant-menu-item-divider {
  background-color: #e8e8e8;
}
.ant-menu-sub > li > a,
.ant-menu-sub > li svg {
  position: relative;
}
.ant-menu-submenu > .ant-menu {
  border-radius: 8px;
  color: #202a3f;
}
.ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
  padding-left: 54px;
}
.ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item,
.ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
  padding-left: 80px;
}
.ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-sub .ant-menu-item,
.ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-sub .ant-menu-item {
  padding-left: 106px;
}
.ant-menu-submenu-popup {
  border-radius: 0;
  z-index: 999;
}
.ant-menu-submenu-popup .ant-menu-sub.ant-menu .ant-menu-item {
  padding-left: 20px;
}
.gx-app-sidebar .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: 22px;
  right: auto;
  display: none;
}
.ant-drawer .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: 22px;
  right: auto;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
  background-image: linear-gradient(to right, #202a3f, #202a3f);
}
.gx-app-sidebar .ant-menu-submenu-vertical .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-vertical-left .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-vertical-right .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-inline .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-left .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-right .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-inline .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: 60px;
  display: none;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
  display: none;
  background: linear-gradient(to right, #fc5027, #fc5027);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #fc5027;
}
.ant-menu-vertical .ant-menu-submenu-selected > a,
.ant-menu-vertical-left .ant-menu-submenu-selected > a,
.ant-menu-vertical-right .ant-menu-submenu-selected > a,
.ant-menu-vertical .ant-menu-submenu-selected svg,
.ant-menu-vertical-left .ant-menu-submenu-selected svg,
.ant-menu-vertical-right .ant-menu-submenu-selected svg {
  fill: #fc5027;
}
.ant-menu-horizontal {
  border-bottom: 4px solid #e8e8e8;
  line-height: 46px;
  padding: 0;
}
.ant-layout-header .ant-menu-horizontal {
  border-bottom: 0 none;
  line-height: 70px;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 4px solid transparent;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 4px solid #fc5027;
  color: #fc5027;
}
.ant-menu-horizontal > .ant-menu-item > a,
.ant-menu-horizontal > .ant-menu-submenu > a,
.ant-menu-horizontal > .ant-menu-item svg,
.ant-menu-horizontal > .ant-menu-submenu svg {
  fill: #202a3f;
}
.ant-menu-horizontal > .ant-menu-item > a:hover,
.ant-menu-horizontal > .ant-menu-submenu > a:hover,
.ant-menu-horizontal > .ant-menu-item svg:hover,
.ant-menu-horizontal > .ant-menu-submenu svg:hover {
  fill: #fc5027;
}
.ant-menu-horizontal > .ant-menu-item > a:before,
.ant-menu-horizontal > .ant-menu-submenu > a:before,
.ant-menu-horizontal > .ant-menu-item svg:before,
.ant-menu-horizontal > .ant-menu-submenu svg:before {
  bottom: -2px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}
.ant-menu-vertical .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-inline .ant-menu-item:after {
  border-right: 6px solid #fc5027;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0;
  text-overflow: inherit;
}
.ant-menu-vertical .ant-menu-item svg,
.ant-menu-vertical-left .ant-menu-item svg,
.ant-menu-vertical-right .ant-menu-item svg,
.ant-menu-inline .ant-menu-item svg,
.ant-menu-vertical .ant-menu-submenu-title svg,
.ant-menu-vertical-left .ant-menu-submenu-title svg,
.ant-menu-vertical-right .ant-menu-submenu-title svg,
.ant-menu-inline .ant-menu-submenu-title svg {
  min-width: 16px;
  margin-right: 20px;
}
.ant-menu-vertical .ant-menu-item svg[class^='svg-']::before,
.ant-menu-vertical-left .ant-menu-item svg[class^='svg-']::before,
.ant-menu-vertical-right .ant-menu-item svg[class^='svg-']::before,
.ant-menu-inline .ant-menu-item svg[class^='svg-']::before,
.ant-menu-vertical .ant-menu-submenu-title svg[class^='svg-']::before,
.ant-menu-vertical-left .ant-menu-submenu-title svg[class^='svg-']::before,
.ant-menu-vertical-right .ant-menu-submenu-title svg[class^='svg-']::before,
.ant-menu-inline .ant-menu-submenu-title svg[class^='svg-']::before,
.ant-menu-vertical .ant-menu-item svg[class*=' svg-']::before,
.ant-menu-vertical-left .ant-menu-item svg[class*=' svg-']::before,
.ant-menu-vertical-right .ant-menu-item svg[class*=' svg-']::before,
.ant-menu-inline .ant-menu-item svg[class*=' svg-']::before,
.ant-menu-vertical .ant-menu-submenu-title svg[class*=' svg-']::before,
.ant-menu-vertical-left .ant-menu-submenu-title svg[class*=' svg-']::before,
.ant-menu-vertical-right .ant-menu-submenu-title svg[class*=' svg-']::before,
.ant-menu-inline .ant-menu-submenu-title svg[class*=' svg-']::before {
  position: relative;
  top: 2px;
}
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item svg + span,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-item svg + span,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-item svg + span,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item svg + span,
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title svg + span,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-submenu-title svg + span,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-submenu-title svg + span,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-submenu-title svg + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
.ant-card .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.ant-card .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.ant-card .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.ant-card .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title {
  padding-left: 54px;
}
.ant-layout-sider .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title {
  padding-left: 80px;
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 30px;
}
.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding-left: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-right: 0px;
}
.ant-menu-inline-collapsed > .ant-menu-item svg + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item svg + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title svg + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
.ant-menu-inline-collapsed-tooltip svg {
  display: none;
}
.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip svg {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-left: 4px;
  padding-right: 4px;
  display: none;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 42px;
  height: 42px;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding: 0 30px 0 80px;
  line-height: 42px;
  height: 42px;
  text-transform: uppercase;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: var(--ant-color-text-tertiary);
}
.ant-menu-item-disabled > a,
.ant-menu-submenu-disabled > a,
.ant-menu-item-disabled svg,
.ant-menu-submenu-disabled svg {
  fill: var(--ant-color-text-tertiary);
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: var(--ant-color-text-tertiary);
}
.ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #fc5027;
}
.ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  display: none;
  background: linear-gradient(to right, #fc5027, #fc5027);
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: #038fdd;
  background: #003366;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  display: none;
  background: #038fdd;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: none;
  box-shadow: none;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom-color: #003366;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  border-color: #003366;
}
.ant-menu-dark .ant-menu-item svg {
  min-width: 16px;
  margin-right: 20px;
}
.ant-menu-dark .ant-menu-item svg[class^='svg-']::before,
.ant-menu-dark .ant-menu-item svg[class*=' svg-']::before {
  position: relative;
  top: 2px;
}
.ant-menu-dark .ant-menu-item-group-title {
  color: #038fdd;
}
.ant-menu-dark .ant-menu-item {
  color: #038fdd;
}
.ant-menu-dark .ant-menu-item > a {
  color: #038fdd;
}
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a {
  color: #ffffff;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-item-selected > a,
.ant-menu svg {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > a,
.ant-menu-dark .ant-menu-item:hover svg,
.ant-menu-dark .ant-menu-item-active svg,
.ant-menu-dark .ant-menu-submenu-active svg,
.ant-menu-dark .ant-menu-submenu-selected svg,
.ant-menu-dark .ant-menu-submenu-title:hover svg,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover svg {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
  display: none;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before {
  display: none;
}
.ant-menu-dark .ant-menu-submenu-open {
  color: #ffffff;
}
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open .ant-menu-submenu-title span,
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-submenu-active .ant-menu-submenu-title span {
  color: #ffffff;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected svg > a:hover,
.ant-menu-dark .ant-menu-item-selected svg:hover {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item-selected span {
  color: inherit;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a,
.ant-menu-dark .ant-menu-item-disabled svg,
.ant-menu-dark .ant-menu-submenu-disabled svg {
  fill: var(--ant-color-text-tertiary);
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: var(--ant-color-text-tertiary);
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-title {
  color: #038fdd;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-open .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #ffffff;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-open .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-submenu-title:hover {
  color: #fa8c15;
}
.ant-menu-submenu-horizontal > .ant-menu-submenu-title {
  color: #202a3f;
}
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
  display: none;
}
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-vertical:hover > .ant-menu-submenu-title {
  color: #ffffff;
}
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-submenu-title svg,
.ant-drawer .ant-menu .ant-menu-submenu .ant-menu-submenu-title svg,
.ant-layout-sider .ant-menu .ant-menu-item svg,
.ant-drawer .ant-menu .ant-menu-item svg {
  font-size: 20px;
}
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-title svg,
.ant-drawer .ant-menu .ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-title svg,
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-item svg,
.ant-drawer .ant-menu .ant-menu-submenu .ant-menu-item svg {
  font-size: 14px;
  margin-right: 12px;
}
.ant-layout-sider .ant-menu .ant-menu-submenu-title span,
.ant-drawer .ant-menu .ant-menu-submenu-title span {
  display: flex;
}
.ant-menu-submenu-popup.ant-menu-light .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-light .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  display: none;
  background-image: linear-gradient(to right, #fc5027, #fc5027);
}
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover,
svg:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover,
svg:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
  display: none;
}
.gx-navbar-nav {
  list-style: none;
  padding-left: 0;
  margin: 0 -5px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.gx-navbar-nav li {
  padding: 0 5px;
}
.gx-navbar-nav li a,
.gx-navbar-nav li svg {
  display: block;
  padding: 4px 10px;
  background-color: #fc5027;
  color: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.gx-profile-banner .gx-navbar-nav li a,
.gx-profile-banner .gx-navbar-nav li svg {
  background-color: transparent;
  color: #ffffff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 0;
}
.gx-navbar-nav li a *,
.gx-navbar-nav li svg * {
  color: #bfbfbf;
}
.gx-navbar-nav li a:hover,
svg:hover .gx-navbar-nav li a:focus,
.gx-navbar-nav li svg:focus {
  background-color: #ed3003;
  color: #ffffff;
}
.gx-profile-banner .gx-navbar-nav li a:hover,
.gx-profile-banner svg:hover .gx-navbar-nav li a:focus,
.gx-profile-banner .gx-navbar-nav li svg:focus {
  background-color: transparent;
  color: #fa8c15;
}
.gx-profile-banner .gx-navbar-nav li {
  padding: 0 15px;
}
@media screen and (max-width: 575px) {
  .gx-profile-banner .gx-navbar-nav li {
    padding: 0 6px;
  }
}
.gx-profile-banner .gx-navbar-nav {
  margin: 0 -15px;
}
@media screen and (max-width: 575px) {
  .gx-profile-banner .gx-navbar-nav {
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    margin: 0 -6px;
  }
}
.gx-menu-horizontal {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.gx-menu-horizontal:before {
  display: none;
}
.gx-header-horizontal-nav .ant-menu-horizontal {
  border-bottom: 0 none;
}
.gx-header-horizontal-nav .ant-menu-submenu-horizontal > .ant-menu-submenu-title {
  color: #ffffff;
}
.gx-below-header-horizontal .gx-header-horizontal-nav .ant-menu-submenu-horizontal > .ant-menu-submenu-title,
.gx-above-header-horizontal .gx-header-horizontal-nav .ant-menu-submenu-horizontal > .ant-menu-submenu-title {
  color: #3a4c72;
}
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item:hover,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu:hover,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-active,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-active,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-open,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-open,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-selected,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom-color: #fa8c15;
  color: #fa8c15;
}
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #fa8c15;
}
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 0 none;
  margin-top: 0;
  top: 0;
}
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected {
  position: relative;
  border-bottom: 0 none;
}
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title {
  position: relative;
}
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before {
  content: '\e049';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  text-align: center;
  font-family: 'gaxon';
  font-size: 31px;
  line-height: 5px;
}
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before {
  color: #f5f5f5;
}
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before {
  color: #003366;
}
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before {
  color: #fa8c15;
}
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before {
  color: #ffffff;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu > .ant-menu {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-top: -7px;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu > .ant-menu .ant-menu-item:first-child {
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu > .ant-menu .ant-menu-item:last-child {
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu > .ant-menu {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fa8c15;
  color: #003366;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected svg {
  color: #003366;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #fa8c15;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  display: none;
  background-image: linear-gradient(to right, #fa8c15, #fa8c15);
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  display: none;
  background-image: linear-gradient(to right, #fa8c15, #fa8c15);
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-item > a:hover,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-item:hover,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-item-active,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-active,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-title:hover {
  color: #fa8c15;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-inside-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-below-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-above-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
  color: #fa8c15;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-inside-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-below-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-above-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-inside-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected svg,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-below-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected svg,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-above-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected svg {
  color: #fa8c15;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed {
  padding-bottom: 10px;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 20px;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item > span,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item > span,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title > span {
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item > a,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item > a {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item.ant-menu-item-selected > a,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item.ant-menu-item-selected > a,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item svg,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item svg {
  color: #003366;
  background-color: #fa8c15;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-submenu.ant-menu-submenu-selected > .ant-menu-submenu-title > span,
.gx-mini-custom-sidebar svg {
  color: #202a3f;
  background-color: #fa8c15;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.gx-mini-custom-sidebar .ant-menu-vertical .ant-menu-item svg,
.gx-mini-custom-sidebar .ant-menu-vertical-left .ant-menu-item svg,
.gx-mini-custom-sidebar .ant-menu-vertical-right .ant-menu-item svg,
.gx-mini-custom-sidebar .ant-menu-inline .ant-menu-item svg,
.gx-mini-custom-sidebar .ant-menu-vertical .ant-menu-submenu-title svg,
.gx-mini-custom-sidebar .ant-menu-vertical-left .ant-menu-submenu-title svg,
.gx-mini-custom-sidebar .ant-menu-vertical-right .ant-menu-submenu-title svg,
.gx-mini-custom-sidebar .ant-menu-inline .ant-menu-submenu-title svg {
  margin-right: 0;
}
.gx-no-header-submenu-popup .ant-menu-sub {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: none;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #fff5f0;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  border-bottom: 4px solid #fc5027;
}
/* Footer Style */
.ant-layout-footer {
  border-top: solid 1px #e5e8ed;
}
@media screen and (max-width: 575px) {
  .ant-layout-footer {
    padding: 14px 15px;
  }
}
.gx-container-wrap .ant-layout-footer {
  padding-left: 0;
  padding-right: 0;
}
.gx-container-wrap .gx-layout-footer-content {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 768px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 920px;
  }
}
@media screen and (min-width: 1200px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 1100px;
  }
}
@media screen and (min-width: 1367px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 1200px;
  }
}
@media screen and (min-width: 1600px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 1400px;
  }
}
.app-footer {
  font-size: 12px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #c2cfd6;
  background: #f0f3f5;
  padding: 0 1rem;
  color: #151b1e;
  flex: 0 0 50px;
  display: flex;
}
.app-footer-product {
  margin-left: auto;
}
.app-footer-product span {
  margin-right: 5px;
}
/* Customizer Style */
.gx-customizer {
  height: calc(100vh - 30px) !important;
}
.gx-customizer-item:not(:last-child) {
  border-bottom: solid 1px #e5e8ed;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.gx-customizer-item .ant-radio-group .ant-radio-button {
  display: none;
}
.gx-customizer-option {
  position: absolute;
  right: 0;
  top: 150px;
  z-index: 99;
}
.full-scroll .gx-customizer-option {
  position: fixed;
}
.full-scroll.boxed-layout .gx-customizer-option {
  position: absolute;
}
.full-scroll.framed-layout .gx-customizer-option {
  right: 20px;
}
@media screen and (max-width: 991px) {
  .full-scroll.framed-layout .gx-customizer-option {
    right: 0;
  }
}
.horizontal-layout.full-scroll .gx-customizer-option {
  top: 200px;
}
@media screen and (max-width: 575px) {
  .gx-customizer {
    width: 250px !important;
  }
  .gx-customizer .ant-radio-button-wrapper {
    padding: 0 10px;
  }
}
.gx-customizer .gx-list-inline {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.framed-layout .gx-customizer {
  height: calc(100vh - 30px - 2 * 20px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-customizer {
    height: calc(100vh - 30px) !important;
  }
}
.gx-color-option {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
.gx-color-option li {
  font-size: 36px;
  line-height: 1;
}
.gx-color-option li .gx-link {
  width: 40px;
  height: 40px;
  display: block;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
}
.gx-color-option li .gx-link:before {
  font-family: 'gaxon';
  font-size: 20px;
  content: '\30';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: none;
}
.gx-color-option li .gx-link.active:before {
  display: block;
}
.gx-color-option li .gx-link:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 20px;
  height: 40px;
  background-color: #9283d4;
}
.gx-color-option li .gx-link.gx-color-red:after {
  background-color: #ff2b7a;
}
.gx-color-option li .gx-link.gx-color-blue:after {
  background-color: #3da4e6;
}
.gx-color-option li .gx-link.gx-color-dark-blue:after {
  background-color: #0469b9;
}
.gx-color-option li .gx-link.gx-color-orange:after {
  background-color: #f18805;
}
.gx-color-option li .gx-link.gx-color-light-blue:after {
  background-color: #6a95ff;
}
.gx-color-option li .gx-link.gx-color-deep-orange:after {
  background-color: #f87060;
}
.gx-color-option li .gx-link.gx-color-light-purple1:after {
  background-color: #a172e7;
}
.gx-color-option li .gx-link.gx-color-light-purple2:after {
  background-color: #956fe7;
}
.gx-cus-customiz {
  padding-right: 20px;
}
.gx-layout-option li,
.gx-nav-option li {
  margin-bottom: 10px;
}
.gx-layout-option li span,
.gx-nav-option li span {
  position: relative;
  display: block;
}
.gx-layout-option li span:before,
.gx-nav-option li span:before {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  font-family: 'gaxon' !important;
  content: '\30';
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: none;
  width: 20px;
  font-size: 12px;
  height: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #fc5027;
  text-align: center;
  padding: 2px;
  color: #ffffff;
}
.gx-layout-option li span.active:before,
.gx-nav-option li span.active:before {
  display: block;
}
/* Drawer Style */
.ant-drawer {
  overflow: hidden;
}
.ant-drawer-body {
  padding: 0;
}
.gx-drawer-sidebar-dark .ant-drawer-content {
  background-color: #003366;
  color: #038fdd;
}
.ant-drawer-content-wrapper {
  width: 280px !important;
}
@media screen and (max-width: 991px) {
  .ant-drawer-content-wrapper {
    width: 260px !important;
  }
}
.framed-layout .ant-drawer-content-wrapper {
  top: 20px;
  bottom: 20px;
  height: calc(100vh - 2 * 20px);
  -webkit-border-radius: 11px 0 0 11px;
  -moz-border-radius: 11px 0 0 11px;
  border-radius: 11px 0 0 11px;
  overflow: hidden;
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer-content-wrapper {
    top: 0;
    bottom: 0;
    height: 100vh;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
  }
}
.ant-drawer-right .ant-drawer-content-wrapper {
  width: 370px !important;
  padding: 15px;
  background: #ffffff;
}
@media screen and (max-width: 991px) {
  .ant-drawer-right .ant-drawer-content-wrapper {
    width: 300px !important;
  }
}
@media screen and (max-width: 575px) {
  .ant-drawer-right .ant-drawer-content-wrapper {
    width: 280px !important;
  }
}
.framed-layout .ant-drawer-right .ant-drawer-content-wrapper {
  top: 20px;
  bottom: 20px;
  height: calc(100vh - 2 * 20px);
  -webkit-border-radius: 0 11px 11px 0;
  -moz-border-radius: 0 11px 11px 0;
  border-radius: 0 11px 11px 0;
  overflow: hidden;
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer-right .ant-drawer-content-wrapper {
    top: 0;
    bottom: 0;
    height: 100vh;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
  }
}
.framed-layout .ant-drawer-content-wrapper,
.boxed-layout .ant-drawer-content-wrapper {
  position: absolute;
}
.boxed-layout .ant-drawer {
  max-width: 1280px;
  margin: 0 auto;
}
@media screen and (min-width: 1600px) {
  .boxed-layout .ant-drawer {
    max-width: 1500px;
  }
}
.framed-layout .ant-drawer-open.ant-drawer-left,
.framed-layout .ant-drawer-open.ant-drawer-right,
.boxed-layout .ant-drawer-open.ant-drawer-left,
.boxed-layout .ant-drawer-open.ant-drawer-right {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.framed-layout .ant-drawer-open {
  width: calc(100% - 2 * 20px);
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer-open {
    width: 100%;
  }
}
@media screen and (min-width: 1600px) {
  .boxed-layout .ant-drawer.ant-drawer-left,
  .boxed-layout .ant-drawer.ant-drawer-right {
    position: absolute;
  }
}
@media screen and (min-width: 991px) {
  .framed-layout .ant-drawer.ant-drawer-left,
  .framed-layout .ant-drawer.ant-drawer-right {
    position: absolute;
  }
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer.ant-drawer-left,
  .framed-layout .ant-drawer.ant-drawer-right {
    right: 20px;
    left: 20px;
    margin: 0;
  }
}
.ant-drawer-close {
  top: -11px;
}
.ant-drawer-close-x {
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.ant-drawer-mask {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
}
/*Login Styles*/
.gx-login-container {
  padding: 0 16px;
  background-image: url(/assets/images/cover.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 100%;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: no-wrap;
  -ms-flex-wrap: no-wrap;
  flex-wrap: no-wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}
@media screen and (max-height: 500px) {
  .gx-login-container {
    height: unset;
    padding: 24px;
  }
}
.gx-login-container h1 {
  font-size: 1.75rem;
  font-weight: 500;
  margin-bottom: 5px;
}
.gx-login-container .card-body h1 {
  font-size: 2.1875rem;
  margin-bottom: 5px;
}
.gx-login-container .card-body .main-text {
  font-size: 44px;
}
.gx-login-container .card-body .secondary-text {
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .gx-login-container .card-body .main-text {
    font-size: 36px;
  }
  .gx-login-container .card-body .secondary-text {
    font-size: 16px;
  }
}
.gx-login-container .card-body .login-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}
.gx-login-container .card-body .login-row a {
  font-weight: 500;
  font-size: 14px;
}
.gx-login-container .card-body .login-row .login-button {
  margin-bottom: 0px;
}
.gx-login-container .card-body .login-row .login-button span {
  font-weight: 500;
  font-size: 14px;
}
.gx-login-container .set-password-form .password-input .ant-form-item-explain,
.gx-login-container .set-password-form .password-input .ant-form-item-explain-connected {
  display: none;
}
.gx-login-container .password-text {
  width: 100%;
  margin: 0px 0px 25px 0px;
}
.gx-login-container .password-text .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #d1fae5;
}
.gx-login-container .password-text .ant-checkbox-disabled + span {
  color: #676c77;
}
.gx-login-container .password-text .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  display: flex;
  margin: 0px;
}
.gx-login-container .no-hover.ant-btn.ant-btn-primary:hover {
  background: none;
  border-color: unset;
}
.gx-login-container::after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(242, 244, 247, 0.9);
}
.gx-login-content {
  max-width: 520px;
  width: 100%;
  padding: 32px 32px;
  background-color: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #e5e8ed;
  font-size: 14px;
  position: relative;
  z-index: 5;
}
.gx-login-content p {
  color: #676c77;
  margin-bottom: 25px;
}
.gx-login-content a {
  color: #fc5027;
}
.gx-login-content .ant-form {
  margin-top: 10px;
}
.gx-login-content .ant-form .ant-form-item {
  margin-bottom: 25px;
}
.gx-login-content .ant-btn-round {
  font-weight: 500;
}
.gx-login-content .ant-input {
  background-color: #ffffff;
  height: auto;
}
.gx-login-content .ant-input:focus {
  box-shadow: none;
  border-color: #fc5027;
}
.gx-login-content .ant-input-affix-wrapper {
  background-color: #ffffff;
  max-height: none;
}
.gx-login-content .ant-form-item-control-wrapper {
  width: 100%;
}
.gx-login-content .ant-form-item-children {
  display: block;
}
@media screen and (max-width: 575px) {
  .gx-login-content {
    padding: 20px 20px 10px;
  }
}
.gx-login-header {
  margin-bottom: 30px;
}
@media screen and (max-width: 575px) {
  .gx-login-header {
    margin-bottom: 15px;
  }
}
.gx-app-login-wrap {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  overflow-x: hidden;
}
@media screen and (max-width: 575px) {
  .gx-app-login-wrap {
    padding-top: 20px;
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }
}
.gx-app-login-container {
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto;
}
@media screen and (max-width: 575px) {
  .gx-app-login-container {
    padding-bottom: 20px;
  }
}
.gx-app-login-container .gx-loader-view {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.gx-app-login-main-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  font-size: 14px;
  overflow: hidden;
}
.gx-app-login-content {
  padding: 35px 35px 20px;
  width: 60%;
}
.gx-app-login-content .ant-input {
  background-color: #f5f5f5;
}
.gx-app-login-content .ant-input:focus {
  box-shadow: none;
  border-color: #fc5027;
}
.gx-app-login-content .gx-btn {
  padding: 6px 35px !important;
  height: auto;
}
.gx-app-login-content .ant-form-item-control-wrapper {
  width: 100%;
}
.gx-app-login-content .ant-form-item-children {
  display: block;
}
@media screen and (max-width: 575px) {
  .gx-app-login-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}
.gx-app-login-header {
  margin-bottom: 30px;
}
@media screen and (max-width: 575px) {
  .gx-app-login-header {
    margin-bottom: 15px;
  }
}
.gx-app-logo-content {
  color: #ffffff;
  padding: 35px 35px 20px;
  width: 40%;
  position: relative;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-app-logo-content > * {
  position: relative;
  z-index: 2;
}
.gx-app-logo-content h1 {
  color: #ffffff;
  font-size: 20px;
}
@media screen and (max-width: 575px) {
  .gx-app-logo-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}
.gx-app-logo-content-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.gx-app-logo-content-bg:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  right: 0;
  bottom: 0;
  background-color: rgba(252, 80, 39, 0.7);
}
.gx-app-logo-content-bg img {
  width: 100%;
  height: 100%;
}
.gx-app-logo-wid {
  margin-bottom: auto;
}
.gx-app-social-block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
}
.gx-app-social-block .gx-social-link,
.gx-app-social-block .gx-social-link li {
  margin: 0;
}
.gx-app-social-block .gx-social-link span {
  border: solid 1px #fc5027;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #fc5027;
}
.gx-app-social-block .gx-social-link span:hover,
.gx-app-social-block .gx-social-link span:focus {
  color: #ffffff;
  background-color: #fc5027;
}
.gx-app-social-block p {
  margin-bottom: 0;
}
.gx-signin-form .ant-form-item-control-input {
  min-height: 40px;
}
.login-logo {
  position: relative;
  z-index: 5;
  display: flex;
  margin-bottom: 40px;
}
.ant-input-group-addon {
  background: #ffffff;
}
.maintenance-content {
  max-width: 710px;
  width: 100%;
  position: relative;
  z-index: 5;
  text-align: center;
}
.logs .logs-filter {
  max-width: calc(100vw - 30%);
}
@media screen and (max-width: 1999px) {
  .logs {
    padding: 0 16px 16px;
  }
}
.logs .ant-tabs-ink-bar {
  background: #fc5027;
}
.logs h1 {
  width: max-content;
}
.logs .ant-tabs-tabpane {
  padding: 20px 20px;
}
@media screen and (max-width: 1999px) {
  .logs .ant-tabs-tabpane {
    height: max-content;
    padding: 12px;
    background: white;
  }
}
.logs .ant-tabs-tabpane .ant-descriptions-item-label {
  color: #676c77;
}
.logs .ant-tabs-tabpane .ant-descriptions-item-content {
  color: #202a3f;
}
.logs .ant-tabs-tabpane .details-owner-description {
  margin-bottom: 25px;
}
.logs .ant-tabs > .ant-tabs-nav,
.logs .ant-tabs > div > .ant-tabs-nav {
  margin-bottom: 0px;
}
.logs .ant-tabs-tab {
  width: 140px;
  display: flex;
  border-radius: 8px 8px 0px 0px;
  justify-content: center;
  background: #e5e8ed;
}
.logs .ant-tabs-tab-active {
  background: #ffffff;
  color: #fc5027;
}
.logs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fc5027;
}
.logs .ant-tabs-tab:hover {
  color: #fc5027;
}
.logs .ant-tabs-tab .ant-tabs-top > .ant-tabs-nav,
.logs .ant-tabs-tab .ant-tabs-bottom > .ant-tabs-nav,
.logs .ant-tabs-tab .ant-tabs-top > div > .ant-tabs-nav,
.logs .ant-tabs-tab .ant-tabs-bottom > div > .ant-tabs-nav {
  margin-bottom: 0px;
}
.logs .ant-tabs-tabpane {
  background: #ffffff;
  border-radius: 0px 8px 8px 8px;
}
.logs .tag-beta.ant-tag {
  border-radius: 2px;
}
.ant-table-thead > tr > th {
  background: #ffffff;
}
.ant-table-content {
  border: 1px solid #e5e8ed;
  border-radius: 8px;
}
.instruction-logs-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.instruction-selector .ant-select-selector {
  display: flex;
  align-items: center;
}
.activity-scroll-wrapper,
.instruction-scroll-wrapper {
  overflow-y: auto;
  height: calc(100vh - 200px);
  min-height: 400px;
  overflow-x: hidden;
}
.activity-scroll-wrapper.image-list .infinite-data-container,
.instruction-scroll-wrapper.image-list .infinite-data-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  width: auto;
}
.gallery-image {
  width: calc(20% - 16px);
  margin: 0 8px;
  position: relative;
}
.gallery-image .ant-image .ant-image-mask:hover {
  opacity: 0;
}
.gallery-image:hover .info-button {
  opacity: 1;
}
@media screen and (max-width: 575px) {
  .gallery-image {
    width: calc(40% - 16px);
  }
}
@media screen and (min-width: 1440px) {
  .gallery-image {
    width: calc(20% - 130px);
  }
}
.gallery-image .ant-popover-arrow {
  display: none;
}
.gallery-image .ant-popover-content {
  width: 310px;
  height: auto;
}
.gallery-image .ant-popover-content .ant-popover-inner {
  background: #f2f4f7;
}
.gallery-image .ant-popover-content .ant-popover-inner .ant-descriptions-item-label,
.gallery-image .ant-popover-content .ant-popover-inner .ant-descriptions-item-content {
  font-size: 16px;
}
.gallery-image .ant-image {
  margin: 0 0 16px 0;
  width: 100%;
}
.gallery-image .ant-image .ant-image-img {
  border-radius: 8px;
  object-fit: cover;
}
.gallery-image .ant-btn-icon-only {
  height: 24px;
  width: 24px;
  padding: 3px 0;
}
.gallery-image .info-button {
  position: absolute;
  right: 10px;
  top: 10px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.35s ease;
}
@media screen and (max-width: 999px) {
  .gallery-image .info-button {
    opacity: 1;
  }
}
.notification .notification-card {
  border: 2px solid #e5e8ed;
  border-radius: 16px;
  margin-top: 16px;
}
.notification .notification-card .ant-card-head {
  background: transparent;
  padding: 0 16px;
}
.notification .notification-card .notification-row:not(:last-of-type) {
  margin-bottom: 16px;
}
.notification .notification-card .title {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
.notification .notification-card .description {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #b3b6c3;
}
.notification .notification-card h4 {
  color: #676c77;
  margin: 0;
}
.notification .notification-card .notification-header {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #676c77;
}
.notification .notification-card .ant-card-body {
  padding: 16px;
}
.notification .notification-card:not(:last-of-type) {
  margin-bottom: 16px;
}
.notification .notification-card .ant-image .ant-image-img {
  object-fit: contain;
}
.notification .notification-card .comment-time {
  font-size: 12px;
  font-weight: 400;
  color: #676c77;
  margin-bottom: 8px;
  line-height: 20px;
}
.notification .notification-card .comment {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
  line-height: 24px;
}
.notification .approver-stage-details-header svg {
  fill: #fc5027;
}
#notification-modal .ant-modal-close-x,
#report-config-modal .ant-modal-close-x {
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#notification-modal .ant-modal-close-x svg,
#report-config-modal .ant-modal-close-x svg {
  fill: #fc5027;
}
#notification-modal .report-config-form .ant-form-item,
#report-config-modal .report-config-form .ant-form-item {
  margin-bottom: 4px;
}
#notification-modal .report-config-form .ant-divider-horizontal,
#report-config-modal .report-config-form .ant-divider-horizontal {
  margin: 14px 0;
}
.ant-col-12 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.agency-header-buttons .ant-btn,
.agency-header-buttons .ant-btn-link {
  margin-right: 5px;
  display: flex;
  align-items: center;
  color: #fff;
  background: #fc5027;
  border-color: #fc5027;
}
.agency-header-buttons .ant-btn svg,
.agency-header-buttons .ant-btn-link svg {
  width: 25px;
  fill: white;
}
.agency-header-buttons .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  text-decoration: none;
  color: white;
  border-color: #ff784f;
  background: #ff784f;
}
@media screen and (min-width: 999px) {
  .global-agency-table {
    height: calc(100vh - 210px);
    overflow: hidden;
  }
}
.global-agency-table .global-agency-table-wrapper {
  height: inherit;
}
.global-agency-table .global-agency-table-wrapper .ant-col {
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.global-agency-table .global-agency-table-wrapper .ant-col::-webkit-scrollbar {
  display: none;
}
.global-agency-table .ant-table .ant-table-tbody .anticon {
  font-size: 16px;
}
a.ant-btn.export-button {
  padding-top: 8px !important;
  text-decoration: none;
}
@media screen and (max-width: 999px) {
  .agency-page-wrapper.ant-card-bordered {
    border: none;
    border-radius: 0;
  }
  .agency-page-wrapper .ant-card-body {
    padding: 0;
  }
  .agency-page-wrapper .agency-header-buttons {
    padding: 16px;
  }
  .ant-table-content {
    border: none;
  }
  #agency-contact-modal .ant-modal {
    height: 100vh;
    top: 0;
    margin: 0;
    max-width: 100vw;
  }
  #agency-contact-modal .ant-modal-content {
    border-radius: 0;
    height: 100vh;
    padding: 0;
  }
  #agency-contact-modal .ant-modal-content .ant-modal-close-x {
    font-size: 22px;
  }
  #agency-contact-modal .ant-modal-content .ant-modal-header {
    border-bottom: none;
    padding: 16px;
    margin-bottom: 0;
  }
  #agency-contact-modal .ant-modal-content .ant-modal-header .ant-modal-title {
    padding-right: 23px;
    font-size: 20px;
  }
  #agency-contact-modal .ant-modal-content .ant-modal-body {
    padding: 0 16px 16px;
  }
  #agency-contact-modal .ant-modal-content .ant-card.ant-card-bordered {
    border: none;
  }
  #agency-contact-modal .ant-modal-content .ant-card.ant-card-bordered h2 {
    margin-top: 10px;
    color: #676c77;
    font-size: 14px;
  }
  #agency-contact-modal .ant-modal-content .ant-card.ant-card-bordered .ant-card-body {
    padding: 0;
  }
  #agency-contact-modal .ant-modal-content .ant-card.ant-card-bordered .ant-card-body .contact-card {
    border: 1px solid #e5e8ed;
    padding: 16px;
  }
  #agency-contact-modal .ant-modal-content .ant-card.ant-card-bordered .contact-scroll-wrapper {
    overflow-y: auto;
    height: calc(100vh - 149px);
    margin-top: 10px;
    min-height: 400px;
  }
  #agency-contact-modal .ant-modal-content .ant-card.ant-card-bordered .contact-action-btn {
    bottom: 10px;
    right: 2px;
  }
  #agency-contact-modal .ant-modal-content .ant-card.ant-card-bordered.skeleton-card {
    border: 1px solid #e5e8ed;
    padding: 16px;
  }
  #agency-contact-modal .ant-modal-content .row-dragging {
    z-index: 10000;
    width: 100%;
    border: none;
  }
  #agency-contact-modal .ant-modal-content .row-dragging .contact-card {
    width: 100%;
  }
}
.users-header-buttons .ant-btn,
.users-header-buttons .ant-btn-link {
  display: flex;
  align-items: center;
  background: #fc5027;
  border-color: #fc5027;
  color: white;
}
.users-header-buttons .ant-btn:not(:last-child),
.users-header-buttons .ant-btn-link:not(:last-child) {
  margin-right: 5px;
}
.users-header-buttons .ant-btn.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover,
.users-header-buttons .ant-btn-link.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  text-decoration: none;
  color: white;
  border-color: #ff784f;
  background: #ff784f;
}
.users-header-buttons .ant-btn svg,
.users-header-buttons .ant-btn-link svg {
  width: 25px;
  fill: white;
}
@media screen and (max-width: 999px) {
  .users {
    padding: 0 16px 16px;
  }
}
.user-action-btn {
  top: 5px;
  right: 0px;
}
#deactive-user-modal .user-activity-count .title {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #676c77;
}
#deactive-user-modal .user-activity-count .title .count {
  color: var(--ant-color-text);
}
#deactive-user-modal .ant-modal-close-x svg {
  fill: #fc5027;
}
#deactive-user-modal .replace-table-wrapper .ant-table-container {
  border: var(--ant-line-width) var(--ant-line-type) var(--ant-table-border-color);
}
#deactive-user-modal .replace-table-wrapper .ant-table-wrapper .ant-table {
  scrollbar-color: unset;
}
#deactive-user-modal .replace-table-wrapper .ant-table-body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
#deactive-user-modal .replace-table-wrapper .ant-table-body::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}
#deactive-user-modal .replace-table-wrapper .ant-table-body::-ms-scrollbar {
  width: 5px;
  height: 5px;
}
#deactive-user-modal .replace-table-wrapper .ant-table-body::-ms-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}
#deactive-user-modal .replace-table-wrapper .ant-select .ant-select-selection-placeholder,
#deactive-user-modal .replace-table-wrapper .ant-select-dropdown .ant-select-item-option-content {
  flex: none;
}
#deactive-user-modal .replace-table-wrapper .ant-select .ant-select-selection-item {
  flex: none;
}
#deactive-user-modal .replace-table-wrapper .ant-table .ant-table-tbody .anticon {
  font-size: unset;
}
#deactive-user-modal .ant-table-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#deactive-user-modal .ant-table-content::-webkit-scrollbar {
  display: none;
}
#deactive-user-modal .ant-modal-centered .ant-modal {
  width: 844px !important;
}
#deactive-user-modal .title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #202a3f;
}
#deactive-user-modal .ant-table-row {
  background-color: #f2f4f7;
}
#deactive-user-modal .replace-button {
  background-color: #fc5027;
  border-radius: 40px;
  color: #ffffff;
  padding: 5px;
}
#deactive-user-modal .background-secondary {
  background-color: #676c77;
}
#deactive-user-modal .text-secondary {
  color: #676c77;
}
#deactive-user-modal .background-primary {
  background-color: #fc5027;
}
#deactive-user-modal .avatar-color {
  margin: 0 5px;
  width: max-content;
}
#deactive-user-modal .ant-table .ant-table-tbody .ant-btn {
  height: 30px;
}
#deactive-user-modal .replaced-button {
  background-color: #202a3f;
}
@media screen and (max-width: 999px) {
  #deactive-user-modal .replace-button {
    font-weight: 600;
    color: #fc5027;
    padding: 10px;
    background-color: transparent;
    border: none;
  }
  #deactive-user-modal .ant-modal-centered .ant-modal {
    height: 100vh;
    top: 0;
    margin: 0;
    max-width: 100vw;
  }
  #deactive-user-modal .ant-modal-content {
    border-radius: 0;
    height: 100vh;
  }
  #deactive-user-modal .common-scroll-wrapper {
    max-height: calc(100vh - 160px);
    height: auto;
    min-height: auto;
  }
  #deactive-user-modal .replaced-button {
    color: #202a3f;
    background-color: transparent;
  }
}
.eqc-header {
  margin-bottom: 15px;
}
.eqc-header .eqc-header-buttons .ant-btn {
  margin-right: 5px;
  display: flex;
  align-items: center;
}
.eqc-header .eqc-header-buttons .ant-btn svg {
  width: 25px;
  fill: #ffffff;
}
.eqc-header-title {
  align-items: center;
}
.eqc-header-title h1 {
  margin-bottom: 0px;
}
.eqc-header-title svg {
  width: 35px;
  height: 35px;
  fill: #fc5027;
  margin-right: 15px;
  margin-bottom: 2px;
}
.details-header {
  align-items: center;
}
.details-header h2 {
  margin-bottom: 0px;
}
.details-header h2 svg {
  margin-right: 5px;
  width: 30px;
  height: 30px;
  fill: #fc5027;
}
.details-header .ant-btn {
  margin-right: 5px;
  display: flex;
  align-items: center;
}
.details-header .ant-btn svg {
  width: 25px;
}
.details-header .edit-button-eqc svg path {
  fill: #ffffff;
}
.details-header .export-button:hover svg {
  fill: #fc5027;
}
.details-header .export-button svg {
  fill: #676c77;
}
.stage-list-card .stage-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  padding: 16px;
}
.stage-list-card .title {
  max-width: 45%;
}
.stage-list-card.project-stages.ant-card-bordered {
  border: 1px solid #e8e8e8;
  border-radius: 12px;
}
@media screen and (min-width: 999px) {
  .stage-list-card .eqc-stage-header-buttons.global-checklist-stage .ant-btn-round {
    padding: 7.9px 14px;
  }
}
.stage-list-card .eqc-stage-header-buttons .import-button {
  background: #e5e8ed;
}
.stage-list-card .eqc-stage-header-buttons .import-button svg {
  fill: #676c77;
}
.stage-list-card .eqc-stage-header-buttons .import-button:hover svg {
  fill: #ff784f;
}
.stage-list-card .eqc-stage-header-buttons .import-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #ff784f;
  border-color: #ff784f;
  background: #e5e8ed;
}
.stage-list-card .eqc-stage-header-buttons .add-button {
  background: #202a3f;
  border: none;
  color: white;
}
.stage-list-card .eqc-stage-header-buttons .add-button:focus,
.stage-list-card .eqc-stage-header-buttons .add-button:hover {
  background: #202a3fd7;
}
.stage-list-card .eqc-stage-header-buttons .add-button[disabled] {
  color: white;
  background: rgba(166, 170, 178);
}
.stage-list-card .eqc-stage-header-buttons .ant-btn {
  margin-right: 5px;
  display: flex;
  align-items: center;
}
.stage-list-card .eqc-stage-header-buttons .ant-btn svg {
  width: 26px;
}
.stage-list-card .ant-table-content {
  border: none;
}
.stage-list-card .stage-row .ant-table-cell {
  padding: 0;
}
.stage-item-card.project-checklist .ant-card-body {
  padding: 0;
}
@media screen and (max-width: 999px) {
  .stage-item-card.project-checklist.ant-card-bordered {
    border: none;
  }
}
.stage-item-card .radio-div {
  padding: 2px 12px;
  border-left: 2px solid #cdd5e0;
  margin-left: 12px;
  height: 25px;
  border-radius: 2px;
}
.stage-item-card .ant-radio-group .active-radio {
  color: #fc5027;
  font-weight: 500;
}
.stage-item-card .ant-radio-wrapper {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #676c77;
}
.stage-item-card .eqc-stage-header-buttons .add-button {
  background: #202a3f;
  border: none;
  color: white;
}
.stage-item-card .eqc-stage-header-buttons .add-button:focus,
.stage-item-card .eqc-stage-header-buttons .add-button:hover {
  background: #202a3fd7;
}
.stage-item-card .eqc-stage-header-buttons .add-button[disabled] {
  color: white;
  background: rgba(166, 170, 178);
}
.stage-item-card .eqc-stage-header-buttons .ant-btn {
  margin-right: 5px;
  display: flex;
  align-items: center;
}
.stage-item-card .eqc-stage-header-buttons .ant-btn svg {
  width: 26px;
}
.stageitem-modal .checklist-item-note-popup {
  display: flex;
  padding: 8px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}
.stageitem-modal .checklist-item-note-popup .ant-popover-arrow .ant-popover-arrow-content {
  --antd-arrow-background-color: #101326;
}
.stageitem-modal .checklist-item-note-popup .ant-popover-inner {
  width: 300px;
  border-radius: 8px;
  background: #101326;
  box-shadow: 0px 25px 50px 0px rgba(81, 89, 131, 0.25);
}
.stageitem-modal .checklist-item-note-popup .ant-popover-inner-content {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.stageitem-modal .ant-divider-dashed {
  border-color: #cdd5e0;
  border-width: 2px 0 0;
}
.stageitem-modal .ant-divider-horizontal {
  margin: 15px 0;
}
.stageitem-modal .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #f5f5f5;
}
.stageitem-modal .ant-checkbox-inner {
  border-radius: 4px;
  width: 20px;
  height: 20px;
}
.stageitem-modal .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner,
.stageitem-modal .ant-checkbox-checked .ant-checkbox-inner,
.stageitem-modal .ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  background: #202a3f;
  border-color: #202a3f;
}
.stageitem-modal .value-input {
  margin: 10px 5px;
  height: 30px;
}
.stageitem-modal .value-input .ant-form-item-control-input {
  width: 131px;
}
.stageitem-modal .numeric-input {
  margin: 10px 5px;
  height: 30px;
}
.stageitem-modal h2 {
  margin: 0;
}
.stageitem-modal .note {
  color: #535353;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-decoration-line: underline;
  width: fit-content;
  padding: 12px 0 16px;
  margin-right: 5px;
}
.grey-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled) {
  background: #f2f4f7;
  border-color: #f2f4f7;
}
.grey-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover,
.grey-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled):focus {
  color: #676c77;
  border-color: #676c77;
}
.optionsInput {
  width: 310px;
}
.row-dragging {
  background: #f2f4f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row-dragging .ant-btn {
  background-color: transparent;
  padding: 0 8px;
  box-shadow: none;
}
.row-dragging .ant-btn svg {
  fill: #fff;
}
.row-dragging td {
  padding: 0 16px;
}
.row-dragging .drag-visible {
  visibility: visible;
}
.eqc-module .ant-table-thead > tr > th {
  color: #676c77;
}
.eqc-module .ant-table-tbody > tr > td {
  color: #202a3f;
}
.drag-icon {
  cursor: grabbing;
  color: #999;
}
@media screen and (max-width: 576px) {
  .stage-item-card .radio-div {
    width: 100%;
    padding: 2px 6px;
    margin: 0px;
    height: 32px;
    display: flex;
    align-items: center;
  }
  .stage-item-card .radio-div .ant-radio-wrapper {
    margin: 0px;
  }
}
@media screen and (max-width: 999px) {
  .stage-list-card.ant-card-bordered {
    border: none;
    border-radius: 0;
  }
  .stage-list-card.ant-card-bordered .ant-card-body {
    padding: 0;
  }
  .stage-list-card.ant-card-bordered .ant-card-body .header {
    padding: 16px 16px 0 16px;
  }
  .stage-list-card.ant-card-bordered .ant-card-body h2 {
    margin-bottom: 0;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .mobile-eqc-type-action-btn button {
    display: block;
    margin-bottom: 10px;
  }
  .eqc-type-scroll-wrapper {
    overflow-y: auto;
    height: calc(100vh - 291px);
    min-height: 400px;
  }
  #project-stage-item-module {
    border: none;
  }
  #project-stage-item-module .ant-card-body {
    padding: 12px;
  }
  #project-stage-item-module .ant-card-body .stage-card {
    border: 1px solid #e8e8e8;
    padding: 16px;
  }
  #project-stage-item-module .stage-item-scroll-wrapper {
    overflow-y: auto;
    height: calc(100vh - 200px);
    margin-top: 10px;
    min-height: 400px;
  }
  #project-stage-item-module .stage-item-scroll-wrapper .infinite-data-container div {
    opacity: unset !important;
  }
  #project-stage-item-module .stage-item-scroll-wrapper .stage-item-action-btn {
    bottom: 10px;
    right: 2px;
  }
  #project-stage-item-module .stage-item-scroll-wrapper .stage-item-action-btn .edit-button button {
    background: none;
    margin-right: 5px;
  }
  #project-stage-item-module.skeleton-card {
    border: 1px solid #e5e8ed;
    padding: 16px;
  }
  #stage-item-modal .ant-modal {
    height: 100vh;
    top: 0;
    margin: 0;
    max-width: 100vw;
  }
  #stage-item-modal .ant-modal-content {
    border-radius: 0;
    height: 100vh;
    padding: 0;
  }
  #stage-item-modal .ant-modal-content .ant-modal-close-x {
    font-size: 22px;
  }
  #stage-item-modal .ant-modal-content .ant-modal-header {
    border-bottom: none;
    padding: 16px;
  }
  #stage-item-modal .ant-modal-content .ant-modal-header .ant-modal-title {
    padding-right: 23px;
    font-size: 20px;
  }
  #stage-item-modal .ant-modal-content .ant-modal-body {
    padding: 0 16px 16px;
  }
  #stage-item-modal .ant-modal-content .ant-card.ant-card-bordered {
    border: none;
  }
  #stage-item-modal .ant-modal-content .ant-card.ant-card-bordered h2 {
    margin-bottom: 0;
    color: #676c77;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  #stage-item-modal .ant-modal-content .ant-card.ant-card-bordered .ant-card-body {
    padding: 0;
  }
  #stage-item-modal .ant-modal-content .ant-card.ant-card-bordered .ant-card-body .stage-card {
    border: 1px solid #e8e8e8;
    padding: 16px;
    margin-bottom: 16px;
  }
  #stage-item-modal .ant-modal-content .ant-card.ant-card-bordered .stage-item-scroll-wrapper {
    overflow-y: auto;
    height: calc(100vh - 200px);
    margin-top: 10px;
    min-height: 400px;
  }
  #stage-item-modal .ant-modal-content .ant-card.ant-card-bordered .stage-item-action-btn {
    bottom: 10px;
    right: 2px;
  }
  #stage-item-modal .ant-modal-content .ant-card.ant-card-bordered.skeleton-card {
    border: 1px solid #e5e8ed;
    padding: 16px;
  }
  #stage-item-modal .ant-modal-content .row-dragging {
    z-index: 10000;
    width: 100%;
    border: none;
  }
  #stage-item-modal .ant-modal-content .row-dragging .ant-btn svg {
    fill: #f2f4f7;
  }
  #stage-item-modal .ant-modal-content .row-dragging .stage-card {
    width: 100%;
  }
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner {
  border-color: #fc5027;
}
.profile-image {
  object-fit: contain;
  width: 123px;
  height: 123px;
  min-width: 123px;
  min-height: 123px;
}
.profile-header {
  margin-bottom: 15px;
  align-items: center;
}
.profile-header h2 {
  margin-bottom: 0px;
}
.profile-header .ant-btn {
  align-items: center;
}
.profile-header .ant-btn svg {
  fill: #ffffff;
}
.profile-content-image {
  position: relative;
  border: 1px solid #e5e8ed;
  border-radius: 4px;
  width: 125px;
  height: 125px;
  margin-right: 20px;
}
.profile-content-image .action-btns {
  position: absolute;
  top: 0;
  right: 0;
  padding: 3px;
}
.profile .ant-descriptions-item-content,
.profile .ant-descriptions-item-label {
  color: #676c77;
}
.profile .ant-descriptions-item-label {
  font-weight: 600;
}
@media screen and (max-width: 999px) {
  .profile-wrapper {
    padding: 16px;
  }
  .profile-header {
    margin-bottom: 0px;
    padding: 16px;
  }
  .profile-header .page-header {
    padding: 0;
  }
}
.cropper-bg {
  background-image: url(/assets/images/cropBackground.png);
}
.cropper-hidden {
  display: none !important;
}
.project-header {
  margin-bottom: 16px;
}
.project-header-title {
  align-items: center;
}
.project-header-title h1 {
  margin-bottom: 0px;
}
.project-header-title svg {
  width: 35px;
  height: 35px;
  fill: #fc5027;
  margin-right: 15px;
  margin-bottom: 2px;
}
.project-header-button .ant-btn {
  display: flex;
  align-items: center;
}
.project-header-button .ant-btn svg {
  width: 25px;
  fill: white;
}
@media screen and (max-width: 999px) {
  .project-module {
    padding: 16px;
  }
}
.project-module .ant-table-thead > tr > th {
  color: #676c77;
}
.project-module .ant-table-tbody > tr > td {
  color: #202a3f;
}
.agency-type-radio .ant-radio-wrapper {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #676c77;
}
.agency-type-radio .ant-radio-wrapper.ant-radio-wrapper-checked {
  color: #fc5027;
}
.deactivated-color {
  background: #f2f4f7;
}
.ant-input-number {
  width: 100%;
}
.ant-input-number .ant-input-number-handler-wrap {
  display: none;
}
.common-map {
  height: 340px;
  width: 100%;
}
.map-row {
  position: relative;
}
.map-input {
  position: absolute;
  border: none;
  z-index: 10;
  height: 40px;
  top: 10px;
  width: 350px;
  left: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}
.project-details .setting-button.ant-btn {
  padding: 4.9px 18px;
}
.project-details .setting-button.ant-btn span:first-child {
  margin: 0;
}
.project-details .nomenclature-info {
  line-height: 1.3;
  max-width: 450px;
}
@media screen and (max-width: 555px) {
  .project-details .nomenclature-info {
    width: 300px;
  }
}
.project-details .nomenclature-info ol {
  margin: 0;
  padding: 0 0 0 25px;
}
.project-details-title-text {
  align-items: center;
}
.project-details-title h1 {
  margin-bottom: 0px;
  font-size: 22px;
}
.project-details-title h2 {
  margin-bottom: 0px;
  color: #fc5027;
  margin-right: 10px;
}
.project-details-title svg {
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.project-details-title .ant-btn svg {
  margin-right: 0px;
}
.project-details-title .edit-button-project {
  align-items: center;
}
.project-details-title .edit-button-project svg path {
  fill: #ffffff;
}
.project-details-title .edit-button-project-rfi {
  align-items: center;
}
.project-details-title .edit-button-project-rfi span {
  margin-left: 0;
}
.project-details-title .edit-button-project-rfi svg {
  height: 18px;
  width: 18px;
}
@media screen and (max-width: 999px) {
  .project-details-title h1 {
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(100vw - 150px);
  }
  .project-details-title svg {
    width: 24px;
    height: 24px;
  }
}
@media screen and (min-width: 1000px) {
  .project-details-title {
    margin-bottom: 15px;
  }
}
.project-details .ant-tabs-tab {
  display: flex;
  min-width: 100px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 8px 8px 0px 0px;
  justify-content: center;
  background: #e5e8ed;
}
.project-details .ant-tabs-tab-active {
  background: #ffffff;
  color: #fc5027;
}
.project-details .ant-tabs-tabpane {
  background: #ffffff;
  border-radius: 0px 8px 8px 8px;
  padding: 20px 20px;
}
.project-details .ant-tabs-top > .ant-tabs-nav,
.project-details .ant-tabs-bottom > .ant-tabs-nav,
.project-details .ant-tabs-top > div > .ant-tabs-nav,
.project-details .ant-tabs-bottom > div > .ant-tabs-nav {
  margin-bottom: 0px;
}
.project-details .checklists .ant-tag {
  line-height: 22px;
}
.project-details .checklist-approvers {
  fill: none;
}
.project-details .checklist-approvers path {
  fill: #fc5027;
}
.project-details .approvers-button svg {
  fill: none;
}
.project-details .approvers-button svg path {
  fill: #f44336;
}
.project-details .checklist-item-tabs .ant-tabs-tabpane {
  padding: 16px;
}
@media screen and (max-width: 999px) {
  .project-details .checklist-item-tabs .ant-tabs-tabpane {
    padding: 12px;
  }
  .project-details .checklist-item-tabs .ant-tabs-tabpane .stage-item-card.project-checklist > .ant-card-body {
    padding: 0;
  }
  .project-details .checklist-item-tabs .ant-tabs-tabpane .stage-item-card.project-checklist > .ant-card-body .infinite-data-container .ant-card-bordered {
    padding: 12px;
  }
}
.project-details .nomenclature-tab {
  padding: 0;
  border: 1px solid #e5e8ed;
}
.project-details .nomenclature-tab #add-edit-levels .ant-input-outlined:hover {
  border-color: #ff784f;
  border-right-width: 1px;
  z-index: 1;
}
.project-details .nomenclature-tab #add-edit-levels .ant-input-outlined:focus-within {
  border-color: #ff784f;
  box-shadow: 0 0 0 2px rgba(252, 80, 39, 0.2);
}
.project-details .nomenclature-tab #add-edit-levels h2 {
  margin: 0;
  line-height: 28px;
}
.project-details .nomenclature-tab #add-edit-levels p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #535353;
  margin-bottom: 10px;
}
.project-details .nomenclature-tab #add-edit-levels .form-inputs {
  width: 100%;
  max-height: 450px;
  overflow: auto;
  overflow-x: hidden;
}
.project-details .nomenclature-tab #add-edit-levels .form-inputs .text-secondary {
  width: 40px;
  text-wrap: nowrap;
}
.project-details .nomenclature-tab #add-edit-levels .form-inputs .remove-col {
  padding: 0 10px 0 0 !important;
}
.project-details .nomenclature-tab #add-edit-levels .form-inputs .ant-form-item {
  width: 100%;
}
.project-details .nomenclature-tab #delete-levels p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #535353;
  margin-bottom: 10px;
}
.project-details .nomenclature-tab #delete-levels b {
  font-size: 12px;
  margin-bottom: 10px;
  display: block;
}
.project-details .nomenclature-tab #delete-levels .click-here-button {
  padding: 0;
  height: max-content;
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}
.project-details .nomenclature-tab #delete-levels ol {
  max-height: 180px;
  overflow: auto;
}
.project-details .nomenclature-tab #request-access-modal p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #535353;
  margin-bottom: 0;
}
.project-details .nomenclature-tab #request-access-modal .duplicate-icon {
  cursor: pointer;
}
.project-details .nomenclature-tab #request-access-modal .duplicate-icon g path {
  fill: #fc5027;
}
.project-details .nomenclature-tab #request-access-modal ol {
  padding: 0 0 0 22px;
}
.project-details .nomenclature-tab #request-access-modal ol .duplicate-icon {
  position: relative;
  top: 5px;
}
.project-details .nomenclature-tab .disabled-state {
  font-size: 16px;
}
.project-details .nomenclature-tab .disabled-state .col-left {
  padding: 20px 0 0 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.project-details .nomenclature-tab .disabled-state .col-left #create-button {
  z-index: 999;
}
.project-details .nomenclature-tab .disabled-state .col-left #create-button .ant-btn-round {
  display: flex;
  align-items: center;
}
.project-details .nomenclature-tab .disabled-state .main-heading {
  font-size: 38px;
  font-weight: 500;
  line-height: 46px;
  text-align: left;
  margin: 0 0 10px 0;
}
.project-details .nomenclature-tab .disabled-state .secondary-heading {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin: 0 0 20px 0;
}
.project-details .nomenclature-tab .disabled-state .col-right {
  text-align: end;
  padding: 0;
}
.project-details .nomenclature-tab .disabled-state .col-right picture {
  position: absolute;
  right: 0;
  z-index: 99;
}
.project-details .nomenclature-tab .disabled-state .col-right iframe {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: relative;
  width: 350px;
  height: 280px;
  border-radius: 16px;
  z-index: 999;
  top: 40px;
  right: 40px;
}
.project-details .nomenclature-tab .disabled-state picture {
  display: block;
  overflow: hidden;
}
@media screen and (min-width: 1000px) {
  .project-details .nomenclature-tab .disabled-state .ant-card-bordered {
    border: 1px solid #e8e8e8;
    box-shadow: 0px 9px 28px 8px #0000000d;
  }
  .project-details .nomenclature-tab .disabled-state .ant-card-bordered .ant-card-body {
    padding: 16px;
  }
}
.project-details .nomenclature-tab .disabled-state .video-card {
  background-color: #d8d8d8;
}
.project-details .nomenclature-tab .disabled-state .mobile-nomenclature-image {
  margin-top: -8%;
}
@media screen and (max-width: 999px) {
  .project-details .nomenclature-tab .disabled-state {
    width: 100%;
  }
  .project-details .nomenclature-tab .disabled-state .first-row {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }
  .project-details .nomenclature-tab .disabled-state .main-heading {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    margin: 0 0 5px 0;
  }
  .project-details .nomenclature-tab .disabled-state .secondary-heading {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
  }
  .project-details .nomenclature-tab .disabled-state .col-left {
    padding: 20px 12px 0 12px;
  }
  .project-details .nomenclature-tab .disabled-state .col-right picture {
    width: 80%;
    max-width: 300px;
    top: -40%;
  }
  .project-details .nomenclature-tab .disabled-state .col-right .round-shape {
    position: relative;
    bottom: 0;
    left: 90px;
    width: 100%;
  }
  .project-details .nomenclature-tab .disabled-state .col-right iframe {
    width: 85%;
    height: 200px;
    top: 15px;
    right: 25px;
  }
}
@media screen and (min-width: 500px) and (max-width: 999px) {
  .project-details .nomenclature-tab .disabled-state .col-right iframe {
    width: 55%;
    height: 250px;
    top: 15px;
    right: 25px;
  }
}
.project-details .tag-new.ant-tag {
  border-radius: 2px;
}
.project-details .nomenclature-info-button path {
  fill: var(--ant-color-text);
}
.project-tab-details .user-action-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content {
  flex: none;
}
.project-tab-details .download-report-pop-confirm {
  width: 250px;
}
.project-tab-details .filter-search .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 40px;
}
.project-tab-details .filter-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 100%;
}
.project-tab-details .filter-search .ant-form-item-control-input-content {
  display: flex;
}
.project-tab-details .filter-search .filter-div {
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 1px solid #e5e8ed;
  border-radius: 40px;
  margin: 0px 16px;
  padding: 4px 8px;
  height: 32px;
}
.project-tab-details .filter-search .filter-div .ant-select {
  width: 100%;
}
.project-tab-details .info-icon path {
  fill: var(--ant-color-text);
}
.project-tab-details .info-popover.ant-popover {
  max-width: 300px;
}
.project-tab-details .info-popover.ant-popover .ant-popover-inner-content {
  width: auto;
  max-width: unset;
}
.project-tab-details .info-popover.ant-popover ul {
  padding-left: 20px;
  margin: 0;
}
.project-tab-details .arrow-button {
  padding: 5px 0;
  background-color: var(--ant-color-text);
  border-color: var(--ant-color-text);
}
.project-tab-details .arrow-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: var(--ant-color-text);
  border-color: var(--ant-color-text);
}
.project-tab-details .arrow-button.ant-btn[disabled] {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
}
.project-tab-details .arrow-button.ant-btn[disabled] svg path {
  fill: rgba(0, 0, 0, 0.25);
}
.project-tab-details .plan-vs-actual-layout {
  border: none;
}
.project-tab-details .plan-vs-actual-layout .main-layout .ant-ribbon {
  top: 4px;
}
.project-tab-details .plan-vs-actual-layout .ant-card {
  background: #e5e8ed;
  margin-bottom: 10px;
}
.project-tab-details .plan-vs-actual-layout .heading-week-div {
  border-radius: 40px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px 30px;
  text-align: center;
  background-color: var(--ant-color-text);
  color: #ffffff;
}
.project-tab-details .plan-vs-actual-layout .heading-week-div div:not(:nth-child(2)) {
  width: 75px;
}
.project-tab-details .plan-vs-actual-layout .heading-week-div div:nth-child(2) {
  margin: 0 10px;
}
@media screen and (max-width: 550px) {
  .project-tab-details .plan-vs-actual-layout .heading-week-div div:nth-child(2) {
    margin: 0 5px;
  }
}
.project-tab-details .plan-vs-actual-layout .statistic-card {
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.project-tab-details .plan-vs-actual-layout .statistic-card .ant-card-body .ant-row {
  width: 100%;
}
.project-tab-details .plan-vs-actual-layout .statistic-card .achieved-stats {
  margin: 2px;
}
.project-tab-details .plan-vs-actual-layout .statistic-card .ant-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.project-tab-details .plan-vs-actual-layout .statistic-card .ant-col .title {
  color: #89889f;
  font-weight: 500;
}
.project-tab-details .plan-vs-actual-layout .statistic-card .ant-col input[type='number']::-webkit-inner-spin-button,
.project-tab-details .plan-vs-actual-layout .statistic-card .ant-col input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.project-tab-details .plan-vs-actual-layout .statistic-card .ant-col .ant-input {
  width: 65px;
  font-size: 18px;
  padding: 0;
  text-align: center;
}
.project-tab-details .plan-vs-actual-layout .statistic-card .ant-col .ant-input::placeholder {
  font-size: 16px;
}
.project-tab-details .plan-vs-actual-layout .statistic-card .ant-col .ant-input:hover {
  background: #fff;
}
.project-tab-details .plan-vs-actual-layout .statistic-card .ant-col .ant-input:focus,
.project-tab-details .plan-vs-actual-layout .statistic-card .ant-col .ant-input-focused {
  box-shadow: none;
}
.project-tab-details .plan-vs-actual-layout .statistic-card .achieved-col .ant-popover-inner {
  width: 300px;
}
.project-tab-details .plan-vs-actual-layout .statistic-card .achieved-col:hover .download-report-button {
  display: block;
}
.project-tab-details .plan-vs-actual-layout .statistic-card .download-report-button {
  display: none;
  position: absolute;
  top: 25px;
  width: auto;
}
.project-tab-details .plan-vs-actual-layout .statistic-card .download-report-button.ant-btn-round {
  padding: 6px 18px;
}
.project-tab-details .plan-vs-actual-layout .statistic-card .download-report-button svg {
  fill: #ffffff;
}
.project-tab-details .plan-vs-actual-layout .ant-divider-vertical {
  height: 1.5em;
  border-left: 2px solid rgba(0, 0, 0, 0.06);
}
.project-tab-details .plan-vs-actual-layout .common-scroll-wrapper {
  height: fit-content;
  min-height: fit-content;
  width: 100%;
}
.project-tab-details .plan-vs-actual-layout .common-scroll-wrapper .infinite-loader {
  display: none;
}
.project-tab-details .plan-vs-actual-layout .info-icon path {
  fill: var(--ant-color-text);
}
.project-tab-details .current-week-button {
  margin-right: 42px;
}
.project-tab-details .plan-vs-actual-card {
  width: calc(33.33% - 10px);
  margin: 5px 5px;
}
.project-tab-details .plan-vs-actual-card .ant-card-body {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 900px) {
  .project-tab-details .plan-vs-actual-card {
    width: calc(50% - 10px);
  }
  .project-tab-details .plan-vs-actual-card .heading-week-div div {
    width: auto;
  }
}
@media screen and (max-width: 550px) {
  .project-tab-details .plan-vs-actual-card {
    width: calc(100% - 10px);
  }
}
.project-tab-details .plan-vs-actual-card .current-week-tag {
  color: #fc5027;
  top: 4px;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  width: 90px;
}
.project-tab-details .plan-vs-actual-card .ant-card-body {
  padding: 16px;
}
.project-tab-details .ant-btn.ant-btn-round.ant-btn-primary.assign-action-button {
  padding: 4px 12px;
  margin: 0;
}
@media screen and (max-width: 999px) {
  .project-tab-details .ant-btn.ant-btn-round.ant-btn-primary.assign-action-button {
    height: 28px;
  }
}
.project-tab-details.nomenclature-module .view-only-tag {
  border-radius: 2px;
  color: #ffffff;
  background-color: #1677ff;
  display: flex;
  align-items: center;
  height: 20px;
}
.project-tab-details.nomenclature-module .ant-segmented-item-selected {
  color: #ffffff;
  background-color: var(--ant-color-text);
}
.project-tab-details.nomenclature-module .ant-segmented-thumb {
  background-color: var(--ant-color-text);
}
.project-tab-details.nomenclature-module .top-row {
  border-bottom: 1px solid #e5e8ed;
}
.project-tab-details.nomenclature-module .top-row .main-action-buttons {
  padding: 5px 5px;
}
.project-tab-details.nomenclature-module .top-row .main-action-buttons .export-button {
  border: 1px solid #e5e8ed;
}
.project-tab-details.nomenclature-module .top-row .main-action-buttons .export-button:hover svg {
  fill: #fc5027;
}
.project-tab-details.nomenclature-module .top-row .main-action-buttons .export-button svg {
  fill: #676c77;
}
.project-tab-details.nomenclature-module .top-row .main-action-buttons .ant-btn-round {
  display: flex;
  align-items: center;
}
.project-tab-details.nomenclature-module .top-row .main-action-buttons .live-tag {
  border-radius: 2px;
  color: #ffffff;
  background-color: #006600;
  height: 20px;
}
.project-tab-details.nomenclature-module .tree-col {
  max-width: 100%;
  width: 100%;
  display: flex;
  border-right: 1px solid #e5e8ed;
}
.project-tab-details.nomenclature-module .tree-col .search-div {
  padding: 5px 10px;
  border-bottom: 1px solid #e5e8ed;
}
.project-tab-details.nomenclature-module .tree-col .search-div .search-component {
  height: 32px;
}
.project-tab-details.nomenclature-module .tree-col .blur-tree-icon {
  width: 356px;
}
.project-tab-details.nomenclature-module .nomenclature-mobile .header {
  padding: 5px;
  border-bottom: 1px solid #e5e8ed;
}
.project-tab-details.nomenclature-module .nomenclature-mobile .header .export-button {
  border: 1px solid #e5e8ed;
}
.project-tab-details.nomenclature-module .nomenclature-mobile .header .ant-btn-round {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-tab-details.nomenclature-module .nomenclature-mobile .detail {
  height: calc(100vh - 288px);
  overflow: auto;
}
.project-tab-details.nomenclature-module .nomenclature-mobile .detail .tree-structure {
  max-width: unset;
  width: unset;
  height: auto;
}
.project-tab-details.nomenclature-module .chart-structure {
  width: 100%;
}
.project-tab-details.nomenclature-module .chart-structure .react-flow {
  height: calc(100vh - 335px) !important;
}
.project-tab-details.nomenclature-module .chart-structure .main-action-buttons {
  padding: 5px 5px;
  border-bottom: 1px solid #e5e8ed;
}
.project-tab-details.nomenclature-module .chart-structure .main-action-buttons .export-button {
  border: 1px solid #e5e8ed;
}
.project-tab-details.nomenclature-module .chart-structure .main-action-buttons .export-button:hover svg {
  fill: #fc5027;
}
.project-tab-details.nomenclature-module .chart-structure .main-action-buttons .export-button svg {
  fill: #676c77;
}
.project-tab-details.nomenclature-module .chart-structure .main-action-buttons .ant-btn-round {
  display: flex;
  align-items: center;
}
.project-tab-details.nomenclature-module .chart-structure .main-action-buttons .live-tag {
  border-radius: 2px;
  color: #ffffff;
  background-color: #006600;
  height: 20px;
}
#assign-agency-modal .ant-steps {
  width: 75%;
}
#assign-agency-modal .ant-modal-close-x svg {
  fill: #fc5027;
}
#assign-agency-modal .no-tag-render .ant-select-selection-overflow-item .ant-select-selection-item {
  display: none;
}
#pause-remark-modal .ant-modal-close-x svg {
  fill: #fc5027;
}
#pause-remark-modal .ant-modal-footer {
  border: none;
  padding: 16px 24px 24px;
  text-align: start;
}
#pause-remark-modal .ant-modal-body {
  padding: 24px 24px 0;
}
#pause-remark-modal .common-scroll-wrapper {
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#pause-remark-modal .common-scroll-wrapper::-webkit-scrollbar {
  display: none;
}
#add-edit-agency-modal .ant-steps {
  width: 75%;
}
#add-edit-agency-modal .ant-divider-dashed {
  border-color: #cdd5e0;
  border-width: 2px 0 0;
}
#add-edit-agency-modal .ant-divider-horizontal {
  margin: 24px 0;
}
#add-edit-agency-modal .create-agency-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #676c77;
}
#add-edit-agency-modal .add-agency-card {
  border: 2px solid #e5e8ed;
  border-radius: 8px;
  margin-top: 16px;
}
#add-edit-agency-modal .add-agency-card .ant-card-body {
  padding: 10px;
}
#add-edit-agency-modal .add-agency-card .ant-form-item:last-child {
  margin-bottom: 0;
}
#add-edit-agency-modal .user-card {
  border: 2px solid #e5e8ed;
  border-radius: 8px;
}
#add-edit-agency-modal .user-card .ant-card-body {
  padding: 0 10px;
}
#add-edit-agency-modal .user-card .ant-btn-round {
  padding: 4px 0;
}
#add-edit-agency-modal .user-card .ant-form-item:last-child {
  margin-bottom: 0;
}
#add-edit-agency-modal .user-card .height-0 {
  height: 0;
}
#add-edit-agency-modal .ant-btn-link {
  padding: 0;
}
@media screen and (max-width: 999px) {
  #add-edit-agency-modal .ant-card-bordered:not(:last-of-type) {
    margin-bottom: 5px;
  }
}
#add-edit-agency-modal .ant-modal-close-x svg {
  fill: #fc5027;
}
#add-edit-agency-modal .no-tag-render .ant-select-selection-overflow-item .ant-select-selection-item {
  display: none;
}
.project-user-modal .rfi-note {
  margin-top: -15px;
}
.project-user-modal h2 {
  font-weight: 500;
}
.project-user-modal .ant-row.ant-form-item {
  margin-bottom: 15px;
}
.project-user-modal .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #676c77;
}
.project-tab-details-header {
  margin-bottom: 15px;
}
.project-tab-details-header h2 {
  margin-bottom: 0px;
}
.project-tab-details .approver-info-icon {
  transform: rotate(180deg);
}
.instruction-tab-details-summary .ant-table-wrapper {
  max-width: 750px;
}
.instruction-tab-details .report-button {
  background: #e5e8ed;
}
.instruction-tab-details .report-button.ant-btn {
  padding-top: 7.9px !important;
}
.instruction-tab-details .report-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  text-decoration: none;
  color: #ff784f;
  border-color: #ff784f;
  background: #e5e8ed;
}
.instruction-tab-details .report-button svg {
  fill: #676c77;
}
.instruction-tab-details .report-button:hover:not([disabled]) svg {
  fill: #fc5027;
}
.instruction-tab-details .report-button.ant-btn[disabled] svg {
  fill: rgba(0, 0, 0, 0.25);
}
.instruction-tab-details .report-button.ant-btn[disabled]:hover svg {
  fill: rgba(0, 0, 0, 0.25);
}
.instruction-tab-details .ant-btn {
  margin-right: 5px;
  display: flex;
  align-items: center;
}
.instruction-tab-details .ant-btn svg {
  width: 26px;
}
.instruction-tab-details .ant-btn span {
  margin-left: 5px;
}
.instruction-tab-details .ant-table-content {
  border: 0px;
}
.instruction-tab-details .ant-table-thead > tr > th,
.instruction-tab-details .ant-table-tbody > tr > td {
  border-bottom: 0px;
}
.instruction-tab-details tbody.ant-table-tbody {
  color: #202a3f;
}
.instruction-tab-details-recommendation .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 300px;
  border-radius: 40px;
}
.instruction-tab-details-recommendation .ant-select-arrow {
  width: 18px;
}
.instruction-tab-details-recommendation td.ant-table-cell {
  max-width: 500px;
}
.instruction-tab-details-recommendation td.ant-table-cell img.image {
  margin-right: 5px;
  flex-shrink: 0;
  cursor: pointer;
  margin-bottom: 5px;
}
.instruction-tab-details-recommendation td.ant-table-cell .ant-image {
  margin-right: 5px;
}
.instruction-tab-details .ant-table-thead > tr > th,
.instruction-tab-details .ant-table-tbody > tr > td,
.instruction-tab-details .ant-table tfoot > tr > th,
.instruction-tab-details .ant-table tfoot > tr > td {
  padding: 5px 5px;
}
.image-none {
  display: none;
}
.ant-image .ant-image-mask:hover {
  opacity: 0;
}
.eqc-tab-details .report-button,
.eqc-type-details .report-button {
  border: none;
  background-color: #e5e8ed;
}
.eqc-tab-details .report-button.ant-btn,
.eqc-type-details .report-button.ant-btn {
  padding-top: 7.9px !important;
}
@media screen and (max-width: 999px) {
  .eqc-tab-details .report-button.ant-btn,
  .eqc-type-details .report-button.ant-btn {
    padding-top: 4.9px !important;
  }
}
.eqc-tab-details .report-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover,
.eqc-type-details .report-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border: none;
  background: #e5e8ed;
  color: #ff784f;
}
.eqc-tab-details .report-button svg,
.eqc-type-details .report-button svg {
  fill: #676c77;
}
.eqc-tab-details .report-button:hover:not([disabled]) svg,
.eqc-type-details .report-button:hover:not([disabled]) svg {
  fill: #fc5027;
}
.eqc-tab-details .report-button.ant-btn[disabled] svg,
.eqc-type-details .report-button.ant-btn[disabled] svg {
  fill: rgba(0, 0, 0, 0.25);
}
.eqc-tab-details .report-button.ant-btn[disabled]:hover svg,
.eqc-type-details .report-button.ant-btn[disabled]:hover svg {
  fill: rgba(0, 0, 0, 0.25);
}
.eqc-tab-details .comment-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled),
.eqc-type-details .comment-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled) {
  background: #202a3f;
  color: #ffffff;
  border: none;
}
.eqc-tab-details .comment-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled) svg,
.eqc-type-details .comment-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled) svg {
  fill: #ffffff;
}
.eqc-tab-details .comment-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled) svg:hover,
.eqc-type-details .comment-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled) svg:hover {
  fill: #fc5027;
}
.eqc-tab-details .ant-btn,
.eqc-type-details .ant-btn {
  margin-right: 5px;
  display: flex;
  align-items: center;
}
.eqc-tab-details .checklist-details.ant-card,
.eqc-type-details .checklist-details.ant-card {
  border-radius: 12px;
}
.eqc-tab-details .checklist-details .edit-button-checklist,
.eqc-type-details .checklist-details .edit-button-checklist {
  align-items: center;
}
.eqc-tab-details .checklist-details .edit-button-checklist svg,
.eqc-type-details .checklist-details .edit-button-checklist svg {
  fill: #ffffff;
}
.eqc-tab-details .checklist-details .unarchive-button-checklist,
.eqc-type-details .checklist-details .unarchive-button-checklist {
  align-items: center;
}
.eqc-tab-details .checklist-details .unarchive-button-checklist span,
.eqc-type-details .checklist-details .unarchive-button-checklist span {
  margin: 0 5px 0 0;
}
.eqc-tab-details .checklist-details .unarchive-button-checklist svg,
.eqc-type-details .checklist-details .unarchive-button-checklist svg {
  height: 18px;
  width: 18px;
  margin-top: 2px;
  fill: #ffffff;
}
.eqc-tab-details .checklist-details .delete-button-checklist,
.eqc-type-details .checklist-details .delete-button-checklist {
  border: 1px solid #d9d9d9;
  align-items: center;
}
.eqc-tab-details .checklist-details .delete-button-checklist:hover svg path,
.eqc-type-details .checklist-details .delete-button-checklist:hover svg path {
  fill: #fc5027;
}
.eqc-tab-details .checklist-details .delete-button-checklist svg,
.eqc-type-details .checklist-details .delete-button-checklist svg {
  fill: none;
}
.eqc-tab-details .checklist-details .delete-button-checklist svg path,
.eqc-type-details .checklist-details .delete-button-checklist svg path {
  fill: var(--ant-color-text);
}
.eqc-tab-details .checklist-details .delete-button-checklist span,
.eqc-type-details .checklist-details .delete-button-checklist span {
  margin: 0;
}
.eqc-tab-details .checklist-details .change-log-button,
.eqc-type-details .checklist-details .change-log-button {
  text-decoration: underline;
  padding: 0;
  color: #676c77;
  cursor: pointer;
  line-height: 22px;
  font-weight: 500;
  margin-top: 5px;
  width: fit-content;
}
@media screen and (max-width: 555px) {
  .eqc-tab-details .checklist-details .checklist-basic-details,
  .eqc-type-details .checklist-details .checklist-basic-details {
    flex-direction: column;
  }
  .eqc-tab-details .checklist-details .checklist-basic-details .checklist-details-form .ant-form-item,
  .eqc-type-details .checklist-details .checklist-basic-details .checklist-details-form .ant-form-item {
    width: 100%;
    margin: 0 0 20px;
  }
  .eqc-tab-details .checklist-details .checklist-basic-details .checklist-details-form .ant-form-item .ant-row.ant-form-item-row,
  .eqc-type-details .checklist-details .checklist-basic-details .checklist-details-form .ant-form-item .ant-row.ant-form-item-row {
    width: 100%;
  }
}
@media screen and (max-width: 555px) {
  .eqc-tab-details .checklist-details .checklist-details-form,
  .eqc-type-details .checklist-details .checklist-details-form {
    flex-wrap: wrap;
  }
}
.eqc-tab-details .checklist-details .checklist-details-form .ant-form-item,
.eqc-type-details .checklist-details .checklist-details-form .ant-form-item {
  margin-right: 20px;
}
.eqc-tab-details .checklist-details .checklist-details-form .ant-row.ant-form-item-row,
.eqc-type-details .checklist-details .checklist-details-form .ant-row.ant-form-item-row {
  width: 200px;
}
.eqc-tab-details .approvers-setting-tab.ant-tabs-tabpane,
.eqc-type-details .approvers-setting-tab.ant-tabs-tabpane {
  padding: 0;
}
.eqc-tab-details .checklist-setting-tab .nested-form,
.eqc-type-details .checklist-setting-tab .nested-form {
  margin: 0;
}
.eqc-tab-details .checklist-setting-tab .nested-form .ant-select-dropdown .ant-select-item-option,
.eqc-type-details .checklist-setting-tab .nested-form .ant-select-dropdown .ant-select-item-option {
  padding: 5px 8px;
}
.eqc-tab-details .checklist-setting-tab .common-dropdown-wrapper .ant-select.ant-select-single .ant-select-selector,
.eqc-type-details .checklist-setting-tab .common-dropdown-wrapper .ant-select.ant-select-single .ant-select-selector {
  border-radius: 8px;
  border: 1px solid #e5e8ed;
  background: #fff;
}
.eqc-tab-details .change-log-module .ant-descriptions-item-label,
.eqc-type-details .change-log-module .ant-descriptions-item-label {
  color: #676c77;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
.eqc-tab-details .change-log-module .ant-descriptions-item-content,
.eqc-type-details .change-log-module .ant-descriptions-item-content {
  color: #202a3f;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.eqc-tab-details .change-log-module .version-log-card,
.eqc-type-details .change-log-module .version-log-card {
  border-radius: 16px;
  border: 2px solid #e5e8ed;
  margin-top: 16px;
}
.eqc-tab-details .change-log-module .version-log-card ol,
.eqc-type-details .change-log-module .version-log-card ol {
  padding-left: 32px;
}
.eqc-tab-details .change-log-module .version-log-card .ant-card-body,
.eqc-type-details .change-log-module .version-log-card .ant-card-body {
  padding: 16px;
}
.eqc-tab-details .change-log-module .version-log-card .title,
.eqc-type-details .change-log-module .version-log-card .title {
  color: #202a3f;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.eqc-tab-details .change-log-module .version-log-card .title .info-text,
.eqc-type-details .change-log-module .version-log-card .title .info-text {
  color: #b3b6c3;
  font-weight: 400;
}
.comment-modal-body .comment-img-wrapper img {
  max-width: 400px;
  max-height: 400px;
  margin-bottom: 20px;
  margin-right: 20px;
}
.eqc-stage-details .ap-info {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #fc5027;
  margin-top: 12px;
}
.eqc-stage-details .no-remark {
  cursor: not-allowed;
  opacity: 0.5;
}
.eqc-stage-details .has-remark {
  cursor: pointer;
}
@media screen and (max-width: 999px) {
  .eqc-stage-details .has-remark {
    fill: #676c77;
  }
}
.eqc-stage-details .approver-stage-details .ant-card-body {
  padding: 0;
}
.eqc-stage-details .approver-stage-details .comment-card .ant-card-body {
  padding: 16px;
}
.eqc-stage-details .approver-stage-details .comment-card .remark-title {
  color: #676c77;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
.eqc-stage-details p {
  margin: 0 0 5px 0;
}
.auditor-comment-modal {
  min-height: 300px;
}
.auditor-comment-modal .auditor-view-comment-wrap {
  display: flex;
  margin: 0.2rem 0 1rem 0;
  flex-direction: column;
  border-bottom: 1px solid #ddd;
}
.auditor-comment-modal .auditor-view-comment-wrap p {
  margin: 0.125rem;
  word-break: break-word;
}
.auditor-comment-modal .auditor-view-comment-wrap p span {
  align-self: flex-end;
  float: right;
  text-align: center;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
}
.project-action-btn {
  top: 5px;
  right: 0px;
}
.project-action-btn .ant-btn-round.ant-btn-icon-only {
  padding: 0;
  margin-right: 5px;
}
@media screen and (max-width: 999px) {
  img.image {
    border-radius: 4px;
  }
  .project-tab-details-header h2 {
    margin-bottom: 0.5em;
  }
  .project-tab-details .filter-search .filter-div {
    margin: 0px 8px 0 0;
    width: 100%;
    margin-bottom: 8px;
  }
  .eqc-tab-details-summary.ant-card-bordered {
    border: none;
  }
  .instruction-tab-details .ant-card-bordered {
    border: none;
  }
  .instruction-tab-details .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
  }
  .project-module.ant-card-bordered {
    border: none;
    border-radius: 0;
  }
  .ant-steps {
    overflow: auto;
  }
  .ant-steps .ant-steps-item {
    overflow: unset;
  }
  .ant-steps .ant-steps-item .ant-steps-item-title::after {
    width: 48px;
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:not(:first-of-type) {
    margin-left: 48px;
  }
}
.recommendation-collapse {
  background: transparent;
}
.recommendation-collapse .ant-collapse-header {
  width: fit-content;
}
.recommendation-collapse .ant-collapse-item {
  border: none;
}
.recommendation-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0;
  margin-bottom: 5px;
}
.recommendation-collapse .panel-header {
  font-weight: 500;
  width: fit-content;
  color: #676c77;
  padding: 8px 16px 8px 16px;
  border-radius: 40px;
  background: #f2f4f7;
}
.recommendation-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.recommendation-collapse .previous-details-card-wrapper {
  padding: 16px;
  border: 1px solid #e5e8ed;
  border-radius: 16px;
}
.approver-info .ant-popover-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: #202a3f;
  padding: 16px 16px 0;
  border: none;
  margin-bottom: 0;
}
.approver-info .ant-popover-title .expand-text.ant-typography {
  font-size: 20px;
}
.approver-info .approvers-card {
  border: none;
}
.approver-info .approvers-card .ant-card-body {
  width: 100%;
  padding: 0;
}
.approver-info .ant-popover-arrow {
  display: none;
}
.approver-info .ant-popover-content {
  width: 350px;
}
.approver-info .ant-popover-inner {
  border-radius: 12px;
  padding: 0;
}
.approver-info .ant-popover-inner-content {
  padding: 16px;
  max-height: calc(100vh - 300px);
  overflow: auto;
}
.approver-info .ant-popover-inner-content .approver-divider {
  margin: 12px 0;
}
@media screen and (max-width: 500px) {
  .approver-info {
    padding: 0 5px;
  }
  .approver-info .ant-popover-content {
    width: 310px;
  }
}
.reports-header {
  margin-bottom: 25px;
}
.reports-header h1 {
  margin-bottom: 0px;
}
.reports-header svg {
  width: 35px;
  fill: #fc5027;
  height: 35px;
  margin-right: 16px;
}
.reports-filters .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 40px;
}
.reports-filters .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 32px;
}
.reports-filters .ant-form-item-control-input-content {
  display: flex;
}
.reports-filters .ant-select-selector {
  display: flex;
  align-items: center;
}
.reports-filters .ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      ) .ant-select-selector {
  box-shadow: none;
}
.reports-filters .filter-div {
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 40px;
  height: 32px;
  margin: 0 4px;
  min-width: 200px;
}
.reports-filters .filter-div .ant-select.ant-select-single.ant-select-allow-clear.ant-select-show-arrow {
  width: 100%;
}
.reports-filters .filter-div .icon {
  height: 22px;
  width: 22px;
  fill: #fc5027;
}
.reports-filters .disabled {
  color: #cfd2d7;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 40px;
  height: 32px;
  margin: 0 4px;
  min-width: 200px;
}
.reports-filters .disabled svg path {
  fill: #cfd2d7;
}
.reports-filters .disabled .ant-select.ant-select-single.ant-select-allow-clear.ant-select-show-arrow {
  width: 100%;
}
.reports-filters .disabled .icon {
  height: 22px;
  width: 22px;
}
.reports-filters .disabled .icon path {
  fill: #cfd2d7;
}
.reports-filters .ant-divider-vertical {
  border: 1px solid #e5e8ed;
  height: 20px;
}
.reports-filters .ant-btn,
.reports-filters .ant-btn-link {
  display: flex;
  align-items: center;
  color: #fff;
  background: #fc5027;
  border-color: #fc5027;
}
.reports-filters .ant-btn svg,
.reports-filters .ant-btn-link svg {
  width: 25px;
  fill: #ffffff;
}
.reports-filters .ant-btn-round {
  height: 34px;
}
.reports-content {
  margin-bottom: 24px;
}
.reports .project-report {
  background: #ffffff;
}
.reports .project-report h3 {
  margin-bottom: 0px;
}
.reports .individual-reports .filter-div {
  width: 100%;
  margin: 0px;
}
.reports .individual-reports .ant-btn,
.reports .individual-reports .ant-btn-link {
  margin-right: 5px;
  display: flex;
  align-items: center;
  color: #fff;
  background: #fc5027;
  border-color: #fc5027;
}
.reports .individual-reports .ant-btn svg,
.reports .individual-reports .ant-btn-link svg {
  width: 25px;
  fill: #ffffff;
}
.reports .individual-reports h3 {
  margin-bottom: 16px;
}
.reports .individual-reports .icon {
  fill: #fc5027;
}
.reports a.ant-btn {
  padding-top: 8px !important;
}
.reports .custom-date-picker {
  background: #ffffff;
  border-radius: 40px;
  box-shadow: none;
  width: 245px;
}
.reports .custom-date-picker .ant-picker-active-bar {
  background: none;
}
.reports .custom-date-picker svg {
  width: 25px;
  fill: #fc5027;
  height: 25px;
  margin-right: 0px;
}
.reports .custom-date-picker input {
  line-height: 1;
}
.reports .blinking-border {
  border: 2px solid #fc5027;
  animation-name: blinking;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@keyframes blinking {
  50% {
    border-color: #ffffff;
  }
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #676c77;
  background: #ffffff;
  border-color: #e5e8ed;
}
.report-dropdown-menu > li.ant-dropdown-menu-item {
  padding: 0 20px;
  height: 42px;
  max-height: 42px;
  font-size: 16px;
  color: #676c77;
}
.report-dropdown-menu > li.ant-dropdown-menu-item:hover {
  color: #fc5027;
  background-color: transparent;
}
.reports-note {
  list-style: decimal;
  font-size: 12px;
}
@media screen and (max-width: 999px) {
  .reports .ant-card-bordered {
    border: none;
    border-radius: 0;
  }
  .reports-filters .disabled,
  .reports-filters .filter-div,
  .reports .individual-reports .ant-btn,
  .reports .individual-reports .ant-btn-link {
    margin: 0;
  }
}
.approval-link {
  background-color: #f2f4f7;
}
@media screen and (max-width: 999px) {
  .approval-link {
    padding: 16px;
  }
}
.approval-link .bypass-card {
  margin-top: 0;
}
.approval-link .eqc-summary-card {
  background: transparent;
  width: 100%;
}
.approval-link .eqc-summary-card .ant-card-body {
  padding: 0;
}
.approval-link .ant-table-content {
  border: 0px;
}
.approval-link .ant-table-thead > tr > th,
.approval-link .ant-table-tbody > tr > td {
  border-bottom: 0px;
}
.approval-link tbody.ant-table-tbody {
  color: #202a3f;
}
.approval-link .ant-table-thead > tr > th,
.approval-link .ant-table-tbody > tr > td,
.approval-link .ant-table tfoot > tr > th,
.approval-link .ant-table tfoot > tr > td {
  padding: 5px 5px;
}
.approval-link .ant-btn {
  margin-right: 5px;
  display: flex;
  align-items: center;
}
.approval-link .ant-btn svg {
  width: 25px;
  fill: #ffffff;
}
.approval-link .ant-descriptions {
  max-width: 70%;
  width: 100%;
}
.approval-link .ant-descriptions-row > th,
.approval-link .ant-descriptions-row > td {
  padding-bottom: 4px;
}
.approval-link .general-comment {
  background: #202a3f;
  border: none;
  color: #ffffff;
}
.approval-link .general-comment:focus,
.approval-link .general-comment:hover {
  background: #202a3fd7 !important;
}
.approval-link .general-comment[disabled] {
  color: #ffffff;
  background: rgba(166, 170, 178);
}
.approval-link .ant-descriptions-item-content {
  display: block;
}
.approval-link .ant-image .ant-image-mask:hover {
  opacity: 0;
}
.approval-link .image-wrapper > div:nth-child(n + 3) {
  display: none;
}
.approval-link .common-item .ant-descriptions-item-content {
  margin-bottom: 20px;
}
.approval-link .ant-descriptions-item-container {
  margin-right: 20px;
}
.approval-link .ant-descriptions-item-container .ant-descriptions-item-label,
.approval-link .ant-descriptions-item-container .ant-descriptions-item-content {
  align-items: center;
}
.approval-link .ant-descriptions-item-label {
  color: #676c77;
}
.approval-link .ant-descriptions-item-content {
  color: #202a3f;
}
.approval-link .previous-comment {
  background: #f2f4f7;
  border-color: #f2f4f7;
  color: #676c77;
  margin-top: 10px;
}
.approval-link .eqc-general-details .main-approver-divider {
  border-top: 2px solid rgba(0, 0, 0, 0.06);
}
.approval-link .eqc-general-details .approver-divider {
  width: 60px;
  min-width: 5px;
}
@media screen and (max-width: 999px) {
  .approval-link .eqc-general-details .approver-divider {
    width: 100%;
    margin: 12px 0;
  }
}
.comment-card {
  border: 2px solid #e5e8ed;
  border-radius: 16px;
  margin-top: 16px;
}
.comment-card h4 {
  color: #676c77;
  margin: 0;
}
.comment-card .additional-title {
  color: #202a3f;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.comment-card .ant-card-body {
  padding: 16px;
}
.comment-card:not(:last-of-type) {
  margin-bottom: 16px;
}
.comment-card .ant-image .ant-image-img {
  object-fit: contain;
}
.comment-card .comment-time {
  font-size: 12px;
  font-weight: 400;
  color: #676c77;
  margin-bottom: 8px;
  line-height: 20px;
}
.comment-card .bold-detail-text {
  font-size: 16px;
  font-weight: 500;
  color: #202a3f;
  line-height: 24px;
}
.comment-card .comment {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
  line-height: 24px;
}
.approver-stage-details-header svg {
  fill: #fc5027;
}
.approver-stage-details .mt-0 {
  margin-top: 0px;
}
.approver-stage-details .additional-text {
  color: #fc5027;
  font-size: 20px;
  font-weight: 400;
  margin: 16px 0px;
  line-height: 28px;
  cursor: pointer;
}
.approver-stage-details .action-button span {
  margin: 0px;
}
.approver-stage-details .checklist-item-text {
  color: #202a3f;
  font-weight: 500;
  line-height: 1.2;
  font-size: 18px;
}
.success-container {
  background-image: url(/assets/images/cover.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 100%;
  flex: 1 1;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.success-container-content {
  padding: 32px 32px;
  font-size: 14px;
  position: relative;
  z-index: 5;
}
.success-container-content h1 {
  font-size: 46px;
  margin-bottom: 80px;
}
.success-container-content svg {
  margin-bottom: 80px;
}
.success-container::after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(242, 244, 247, 0.9);
}
.approvers-card {
  border: 1px solid #e5e8ed;
  border-radius: 4px;
  height: fit-content;
}
.approvers-card .ant-card-body {
  padding: 12px;
  width: 280px;
  color: #676c77;
}
.approvers-card .ant-tag.approver-name-tag {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}
.approvers-card .ant-tag.approver-name-tag .expand-text.ant-typography {
  font-size: 12px;
}
.approvers-card .level-name {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
@media screen and (max-width: 999px) {
  .approvers-card {
    border: none;
  }
  .approvers-card .ant-card-body {
    padding: 0;
    width: 100%;
    min-width: unset;
  }
}
.comment-popup .ant-divider-horizontal {
  margin: 16px 0;
}
.comment-popup .add-comment-button {
  border-radius: 8px;
  border: 1px solid #e5e8ed;
  padding: 16px;
}
.comment-popup .level-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #595959;
}
.comment-popup .ant-popover-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: #202a3f;
  padding: 12px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 0;
}
.comment-popup .ant-popover-arrow {
  display: none;
}
.comment-popup .ant-popover-content {
  width: 400px;
}
.comment-popup .ant-popover-inner {
  border-radius: 12px;
  padding: 0;
}
.comment-popup .ant-popover-inner-content {
  padding: 16px;
  max-height: calc(100vh - 300px);
  overflow: auto;
}
.comment-popup .comment-data-card {
  border: 1px solid #e5e8ed;
  border-radius: 8px;
  padding: 16px;
}
.comment-popup .comment-data-card .name-and-time {
  font-size: 12px;
  line-height: 20px;
}
.comment-popup .comment-data-card .comment {
  font-size: 16px;
  color: #676c77;
  font-weight: 400;
  line-height: 24px;
}
@media screen and (max-width: 500px) {
  .comment-popup {
    padding: 0 5px;
  }
  .comment-popup .ant-popover-content {
    width: 310px;
  }
}
.approval-confirm-modal .ant-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.approval-confirm-modal .title {
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  color: #202a3f;
}
.approval-confirm-modal .description {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #676c77;
}
.approval-confirm-modal .ant-btn-round {
  font-weight: 500;
  padding: 7.9px 40px;
}
.approval-confirm-modal .ant-modal-content {
  border-radius: 16px;
  width: 350px;
}
.approval-selection-modal .ant-form-item:last-child {
  margin-bottom: 0;
}
.general-comment {
  background: #202a3f;
  border: none;
  color: #ffffff;
}
.general-comment:focus,
.general-comment:hover {
  background: #202a3fd7 !important;
}
.general-comment[disabled] {
  color: #ffffff;
  background: rgba(166, 170, 178);
}
.dashboard-wrapper h3 {
  margin-bottom: 0;
}
.dashboard-wrapper .ant-collapse {
  border: none;
  background: transparent;
}
.dashboard-wrapper .ant-collapse > .ant-collapse-item {
  border-bottom: none;
  margin-bottom: 16px;
  background: white;
}
.dashboard-wrapper .ant-collapse-content {
  border: none;
}
.dashboard-wrapper .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.dashboard-wrapper .common-card-wrapper {
  padding-top: 0;
}
li.recharts-legend-item {
  margin: 5px;
}
.custom-date-picker {
  background: #ffffff;
  border-radius: 40px;
  box-shadow: none;
  width: 245px;
  height: 32px;
}
.custom-date-picker .ant-picker-active-bar {
  background: none;
}
.custom-date-picker svg {
  width: 22px;
  fill: #fc5027;
  height: 22px;
  margin-right: 0px;
}
.custom-date-picker input {
  line-height: 1;
}
@media screen and (max-width: 999px) {
  .custom-date-picker-panel .ant-picker-panel:last-child {
    width: 0;
  }
  .custom-date-picker-panel .ant-picker-panel:last-child .ant-picker-header {
    position: absolute;
    right: 0;
  }
  .custom-date-picker-panel .ant-picker-panel:last-child .ant-picker-header .ant-picker-header-prev-btn,
  .custom-date-picker-panel .ant-picker-panel:last-child .ant-picker-header .ant-picker-header-view {
    visibility: hidden;
  }
  .custom-date-picker-panel .ant-picker-panel:last-child .ant-picker-body {
    display: none;
  }
  .custom-date-picker-panel .ant-picker-footer-extra {
    line-height: 18px;
    padding: 8px 12px;
  }
}
.report-image-viewer header {
  padding: 8px 16px;
}
.report-image-viewer-content h2 {
  margin-bottom: 40px;
}
.report-image-viewer-content .ant-image {
  width: 15%;
  margin: 0 25px 25px 0;
}
.report-image-viewer-content .ant-card-body .audio-player {
  width: 50%;
}
@media screen and (max-width: 768px) {
  .report-image-viewer-content .ant-card-body .audio-player {
    width: 100%;
  }
}
.status-tag.ant-tag {
  font-weight: 500;
  border: solid 1px;
}
.issue-detail-layout {
  height: calc(100vh - 260px);
  overflow: auto;
}
@media screen and (max-width: 999px) {
  .issue-detail-layout {
    height: calc(100vh - 236px);
  }
}
.fixed-height {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 999px) {
  .fixed-height {
    padding: 0 !important;
  }
}
.instruction-tab-details-summary {
  background-color: #fafafa;
}
.instruction-tab-details-summary .ant-card-body {
  padding: 16px;
}
.instruction-tab-details-summary .view-details {
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  height: auto;
  margin-top: 8px;
}
.instruction-tab-details-summary .ant-divider-horizontal {
  margin: 24px -16px;
  width: unset;
}
.deactivated-color {
  background: #f2f4f7;
}
.empty-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}
.empty-text-secondary {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #595959;
  margin-top: 8px;
}
.clear-filter-button {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #096dd9;
}
.clear-filter-button:focus,
.clear-filter-button:hover {
  color: #096dd9;
}
@media screen and (max-width: 999px) {
  .clear-filter-button {
    padding: 0;
  }
}
.issue-heading {
  max-width: 50%;
}
.response-card {
  background-color: #f5f5f5;
  width: 448px;
}
.response-card .ant-card-body {
  padding: 16px;
}
.response-card .ant-form-item:not(:first-child) {
  margin-bottom: 0px;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  width: 72px;
  height: 72px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select .add-button {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-upload-list-picture-card-container {
  width: 72px;
  height: 72px;
}
.ant-badge-status-dot {
  width: 4px;
  height: 4px;
}
.recommendation-collapse {
  background: transparent;
}
.recommendation-collapse .ant-collapse-header {
  width: fit-content;
}
.recommendation-collapse .ant-collapse-item {
  border: none;
}
.recommendation-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0;
  margin-bottom: 5px;
}
.recommendation-collapse .panel-header {
  font-weight: 500;
  width: fit-content;
  color: #535353;
  padding: 8px 12px 8px 12px;
  border-radius: 40px;
  border: 1px solid;
  background: #f2f4f7;
}
.recommendation-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.recommendation-collapse .previous-details-card-wrapper {
  padding: 16px;
  border: 1px solid #e5e8ed;
  border-radius: 16px;
}
.ant-modal-footer {
  border-top: none;
  padding: 0 16px 16px;
}
@media screen and (max-width: 999px) {
  .add-response-modal.ant-modal {
    max-width: 100%;
    bottom: 0;
    margin: 0;
    vertical-align: bottom;
    padding: 0;
  }
  .add-response-modal.ant-modal .ant-modal-content {
    border-end-end-radius: 0;
    border-end-start-radius: 0;
    max-height: 90vh;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
  }
}
.success-container {
  background-image: url(/assets/images/cover.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 100%;
  flex: 1 1;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.success-container-content {
  padding: 32px 32px;
  font-size: 14px;
  position: relative;
  z-index: 5;
}
.success-container-content h1 {
  font-size: 32px;
  margin-bottom: 80px;
}
.success-container-content svg {
  margin-bottom: 80px;
}
.success-container::after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(242, 244, 247, 0.9);
}
.issue-activity {
  background-color: #ffffff;
}
.issue-activity .response-detail:not(:first-child) {
  margin-top: 24px;
}
.issue-activity .response-detail .name-and-time {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #676c77;
}
.issue-activity .response-detail .name-and-time .ellipsis-text {
  max-width: 200px;
}
.issue-activity .response-detail .ant-card-body {
  padding: 16px;
}
.issue-activity .response-detail .ant-card-bordered {
  max-width: 384px;
  width: fit-content;
}
@media screen and (max-width: 999px) {
  .issue-activity .response-detail .ant-card-bordered {
    max-width: 310px;
  }
}
.issue-activity .response-detail .grey-background {
  background-color: #f5f5f5;
}
.issue-report-button {
  background: #202a3f;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}
.issue-report-button.ant-btn:hover,
.issue-report-button.ant-btn:focus,
.issue-report-button.ant-btn:active {
  background: #202a3f;
  color: #ffffff;
  border: 1px solid #202a3f;
}
.issue-report-button svg {
  fill: #ffffff;
}
.issue-report-button.ant-btn[disabled] svg {
  fill: rgba(0, 0, 0, 0.25);
}
.issue-report-button.ant-btn[disabled]:hover svg {
  fill: rgba(0, 0, 0, 0.25);
}
.add-response-button {
  position: sticky;
  bottom: 0;
  left: 0;
  margin: 0 -16px;
  padding: 12px 16px 0;
}
.add-response-button button {
  height: 46px;
}
.ant-descriptions .ant-descriptions-item-container .ant-descriptions-item-label {
  color: #595959;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}
.ant-descriptions .ant-descriptions-item-container .ant-descriptions-item-content {
  color: #262626;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
.ant-descriptions .ant-descriptions-row > td {
  padding-bottom: 5px;
}
.ant-descriptions .ant-descriptions-row > th {
  padding: 0;
}
#view-details-drawer .ant-drawer-content-wrapper,
#add-response-drawer .ant-drawer-content-wrapper {
  width: 100% !important;
}
#view-details-drawer .ant-drawer-body,
#add-response-drawer .ant-drawer-body {
  padding: 16px;
}
#view-details-drawer .ant-drawer-content,
#add-response-drawer .ant-drawer-content {
  border-radius: 16px 16px 0px 0px;
}
#view-details-drawer .ant-drawer-header-title,
#add-response-drawer .ant-drawer-header-title {
  flex-direction: row-reverse;
}
#view-details-drawer .ant-drawer-header,
#add-response-drawer .ant-drawer-header {
  padding: 16px;
}
#view-details-drawer .ant-drawer-header .ant-drawer-title,
#add-response-drawer .ant-drawer-header .ant-drawer-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #202a3f;
}
#view-details-drawer .ant-drawer-header .ant-drawer-close,
#add-response-drawer .ant-drawer-header .ant-drawer-close {
  margin: 0;
}
@media screen and (max-width: 999px) {
  .ant-card-bordered {
    border: 1px solid #e5e8ed;
  }
  .ant-card-body {
    padding: 16px;
  }
  .ant-card-bordered:not(:last-of-type) {
    margin-bottom: 16px;
  }
  .filters-search .filter-div {
    margin: 0;
  }
  .ant-btn-round.ant-btn-icon-only {
    padding: 5px;
  }
  .mobile-card-wrapper .issue-detail-card > .ant-card-body {
    padding: 0 12px;
  }
  .mobile-card-wrapper .ant-card-body {
    padding: 0;
    width: 100%;
  }
  .mobile-card-wrapper .instruction-tab-details-summary {
    margin-bottom: 24px;
  }
  .mobile-card-wrapper .instruction-tab-details-summary.ant-card {
    border-radius: 0;
    border-width: 1px 0;
  }
  .mobile-card-wrapper .issue-activity-mobile {
    height: auto;
    flex: 1;
    overflow: visible;
  }
  .mobile-card-wrapper .issue-activity-mobile.raised-issues {
    display: flex;
    align-items: flex-end;
  }
  .map-input {
    width: 230px;
  }
  .search-component svg {
    fill: #535353;
  }
}
#root {
  height: 100vh;
}
.header-notification {
  display: flex;
  align-items: center;
}
.header-notification > *:not(:last-child) {
  margin-right: 20px;
}
.header-notification > .notification-bell {
  font-size: 20px;
  cursor: pointer;
}
.mobile-header {
  padding: 16px;
  background: white;
  height: 72px;
}
.mobile-header .logo {
  padding: 0;
  height: unset;
}
.mobile-header .ant-btn {
  background: transparent;
  box-shadow: none;
}
.mobile-header .header-notification > * {
  margin-right: 10px;
}
.mobile-header .header-notification .profileImage {
  margin: 0;
}
#nav-bar-dropdown .ant-dropdown {
  top: 72px !important;
  width: 100%;
  box-shadow: 0px 8px 11px 2px #ccc;
}
#nav-bar-dropdown .ant-dropdown-menu {
  border-radius: 0;
  box-shadow: 0px 3px 3px -4px rgba(0, 0, 0, 0.12), 0 6px 6px 0 rgba(0, 0, 0, 0.08), 0 9px 9px 8px rgba(0, 0, 0, 0.05);
  position: absolute;
  width: 100%;
  right: calc(-100% + 33px + 16px);
  padding: 0;
}
#nav-bar-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 10px 32px;
  font-size: 16px;
}
.small-btn {
  height: 24px;
  padding: 2px 8px;
}
.small-btn span {
  font-size: 12px;
}
@media screen and (max-width: 999px) {
  .page-header {
    padding: 16px;
    font-size: 20px;
    font-weight: 500;
  }
  .common-card-wrapper {
    background: white;
    padding: 16px;
  }
}
@media screen and (max-width: 575px) {
  .ant-layout-header {
    height: unset;
  }
}
.gx-sidebar-content > .ant-menu > .ant-menu-item {
  margin: 12px 0;
}
.gx-sidebar-content > .ant-menu > .ant-menu-item > .menu-icons {
  font-size: 20px;
}
.gx-sidebar-content > .ant-menu > .ant-menu-item > span {
  font-size: 18px;
}
.ant-table .ant-table-tbody .anticon {
  font-size: 20px;
  padding: 0 4px;
}
.ant-table .ant-table-tbody .ant-btn {
  margin: 0;
  padding: 0;
  height: auto;
  border: none;
}
.ant-table-tbody > tr > td {
  max-width: 500px;
}
tr.ant-table-placeholder > td.ant-table-cell {
  border-bottom: none;
}
.ant-table tr:last-of-type td {
  border-bottom: none;
}
.ant-table-wrapper .ant-table-tbody > tr > td {
  transition: none;
}
.event-header-image .ant-upload-select-picture-card {
  width: 100%;
}
.event-header-image.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}
.event-form .ant-form-item-label {
  padding: 0;
  margin: 0;
}
.ant-message-custom-content {
  display: flex;
  align-items: center;
}
.ant-message-custom-content > .ant-message-custom-content .ant-message-info {
  height: 36px;
  display: flex;
  align-items: center;
}
.icon-btn {
  display: flex;
  align-items: center;
}
.upload-image-container .ant-upload-select-picture-card {
  width: 100%;
}
.upload-image-container.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}
.custom-icons-list {
  margin-right: 10px;
}
.ant-table-column-sorters {
  padding: 0;
}
.login-content {
  padding: 35px;
  width: 100%;
}
.ant-input-prefix {
  display: flex;
  align-items: center;
  justify-content: center;
}
.reset-password {
  text-align: center;
}
.ant-btn-sm {
  line-height: 0;
}
.attendee-details .ant-form-item {
  min-width: 215px;
  margin-right: 16px;
}
.rsvp-modal .ant-modal-footer div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ant-input-suffix {
  display: flex;
  align-items: center;
}
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  box-shadow: 0 0 0 2px rgba(252, 80, 39, 0.2);
}
.option-input {
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;
}
.option-input:focus {
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;
  box-shadow: unset;
}
.option-input:hover {
  border-color: transparent;
  border-right-width: 0;
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;
}
.option-input.ant-input-affix-wrapper-focused {
  box-shadow: unset;
}
.pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.donation-list {
  overflow: auto;
}
.donation-card {
  border: 1px solid #e5e5e5;
  padding: 14px 32px;
  border-radius: 4px;
  margin-right: 24px;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  min-height: 100px;
  margin-bottom: 12px;
}
.donation-card.active {
  background-color: #efefef;
}
.ministry-modal {
  height: 500px;
}
.ministry-modal .ant-modal-body {
  padding: 0;
}
.ministry-modal object {
  min-height: 500px;
}
.dialog .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dialog .ant-modal-footer > button:first-child {
  margin-right: 0px;
}
.action-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.action-icons .kebab-icon.ant-btn {
  padding: 6px;
  background: #f2f4f7;
  height: 32px;
}
.action-icons button:first-child {
  margin-right: 12px;
}
.action-icons > button {
  margin-bottom: 0;
}
.m-0 {
  margin: 0;
}
.mt-5 {
  margin-top: 5px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-50 {
  margin-top: 50px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-16 {
  margin-bottom: 16px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-12 {
  margin-left: 12px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-16 {
  margin-left: 16px;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-8 {
  margin-right: 8px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-16 {
  margin-right: 16px;
}
.mr-20 {
  margin-right: 20px;
}
.p-0 {
  padding: 0;
}
.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px;
}
.p-14 {
  padding: 14px;
}
.p-16 {
  padding: 16px;
}
.pb-10 {
  padding-bottom: 10px;
}
.d-flex {
  display: flex;
}
.d-flex-wrap {
  flex-wrap: wrap;
}
.d-flex-nowrap {
  flex-wrap: nowrap;
}
.d-none {
  display: none;
}
.d-inline-flex {
  display: inline-flex;
}
.d-grid {
  display: grid;
}
.flex-horizontal {
  flex-direction: row;
}
.flex-vertical {
  flex-direction: column;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-grow-2 {
  flex-grow: 2;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-shrink-1 {
  flex-shrink: 1;
}
.flex-shrink-2 {
  flex-shrink: 2;
}
.flex-wrap {
  flex-wrap: wrap;
}
.align-self-end {
  align-self: flex-end;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-10 {
  font-size: 10px;
}
.font-size-22 {
  font-size: 22px;
}
.height-auto {
  height: auto;
}
.fill-height {
  height: 100%;
}
.height-auto {
  height: auto;
}
.width-auto {
  width: auto;
}
.width-percent-20 {
  width: 20%;
}
.width-600 {
  width: 600px;
}
.width-400 {
  width: 400px;
}
.width-300 {
  width: 300px;
}
.width-200 {
  width: 200px;
}
.max-width-900 {
  max-width: 900px;
}
.width-percent-50 {
  width: 50%;
}
.width-percent-60 {
  width: 60%;
}
.width-percent-40 {
  width: 40%;
}
.width-percent-5 {
  width: 5%;
}
.height-percent-100 {
  height: 100%;
}
.width-percent-100 {
  width: 100%;
}
.width-percent-25 {
  width: 25%;
}
.fill-width {
  width: 100%;
}
.pointer {
  cursor: pointer;
}
.cursor-disabled {
  cursor: not-allowed;
}
.pointerEvents-none {
  pointer-events: none;
}
.b-0 {
  border: none;
}
.stage-config .notification-row:not(:last-of-type) {
  margin-bottom: 16px;
}
.stage-config .disabled-background {
  background-color: #f2f4f7;
}
.stage-config .config-div {
  padding: 0 5px;
}
.stage-config .note-text {
  margin: -6px 0 10px 0;
}
.stage-config .title {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
.stage-config .description {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #b3b6c3;
}
.form-buttons {
  display: flex;
  justify-content: flex-end;
}
.form-buttons button:not(:last-of-type) {
  margin-right: 10px;
}
.form-buttons .ant-btn-default:not(:disabled).cancel-button {
  background: #f2f4f7;
  border-radius: 40px;
  color: #676c77;
  border-color: #f2f4f7;
}
.form-buttons .save-button {
  border: none;
}
.change-password-content {
  display: flex;
  justify-content: center;
}
.gx-login-content .ant-form .ant-form-item:first-of-type {
  margin-bottom: 10px;
}
.set-password-form .ant-form-item-with-help {
  margin-bottom: 20px;
}
.set-password-form .password-input .ant-form-item-explain,
.set-password-form .password-input .ant-form-item-explain-connected {
  display: none;
}
.password-text {
  width: 100%;
  margin: 0px 0px 25px 0px;
}
.password-text .ant-checkbox-group {
  display: inline-block;
}
.password-text .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #d1fae5;
}
.password-text .ant-checkbox-disabled + span {
  color: #676c77;
}
.password-text .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  display: flex;
  margin: 0px;
}
.change-password-inside-content {
  max-width: 600px;
  width: 100%;
  margin: 16px;
  padding: 20px 20px 20px;
  background-color: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  font-size: 14px;
  position: relative;
  z-index: 5;
}
.change-password-inside-content .ant-form-item:nth-child(2) {
  margin-bottom: 10px;
}
.ant-tag {
  border-radius: 10px;
  border: none;
}
.light-tag {
  background: #e5e8ed;
}
.inactive-tag {
  background: #e5e8ed;
  color: #676c77;
}
.draft-tag {
  background: #595959;
  color: #ffffff;
}
.active-tag {
  background: #d1fae5;
  color: #065f46;
}
.yellow-tag {
  background: #ffe7ba;
  color: #ad4e00;
}
.red-tag {
  background: #ffccc7;
  color: #a8071a;
}
.search-component {
  border-radius: 40px;
  padding: 4px 8px;
  height: 100%;
}
.search-component input {
  line-height: normal;
}
.table .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  background: #ffffff;
}
.table .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px;
  border: 1px solid #e5e8ed;
}
.table .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: auto;
}
.table .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    ) .ant-select-selector {
  border: 1px solid #e5e8ed;
}
.ant-table .ant-table-tbody .ant-btn {
  height: 32px;
  margin-right: 20px;
}
.header h1 {
  margin-bottom: 0px;
}
.ant-switch.ant-switch-checked,
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #202a3f;
}
.ant-switch:hover:not(.ant-switch-disabled) {
  background: rgba(0, 0, 0, 0.25);
}
.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.userprofile-divider {
  margin: 5px 0;
  border: 1px dashed;
}
.sample-file {
  color: #fc5027;
}
.text-primary {
  color: #fc5027;
}
.import-divider {
  margin: 10px 0;
  border-color: #000000;
}
.import-modal {
  width: 570px !important;
}
.import-modal .import-required .ant-row.ant-form-item {
  margin-bottom: 0px;
}
.import-modal ul {
  margin-bottom: 0px;
}
.import-modal .import-additional-info h4 {
  min-width: fit-content;
}
.import-modal .import-error h4 {
  color: red;
}
.import-modal .import-error .ant-btn {
  background: #e5e8ed;
}
.import-modal .note-error {
  color: red;
}
.text-no-break {
  word-break: keep-all;
}
.text-break {
  word-break: break-word;
}
.ant-btn span {
  line-height: normal;
}
.ant-table-wrapper .ant-table-thead > tr > th {
  color: #676c77;
  font-weight: 500;
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td {
  background: #f2f4f7;
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td .ant-btn svg {
  fill: white;
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td .kebab-icon.ant-btn {
  background: #ffffff;
}
.checklist-row .ant-table-tbody > tr.ant-table-row:hover > td {
  background: #fed3c9;
}
.checklist-row .ant-table-tbody > tr.ant-table-row:hover > td .ant-btn svg {
  fill: white;
}
.checklist-row .ant-table-tbody > tr.ant-table-row:hover > td .kebab-icon.ant-btn {
  background: #ffffff;
}
.checklist-row .checklist-approvers {
  fill: none;
}
.checklist-row .checklist-approvers path {
  fill: #fc5027;
}
.checklist-row .ant-divider-horizontal {
  margin: 0;
}
.row-dark {
  background: #f2f4f7;
}
.row-dark .action-icons svg {
  fill: white;
}
.row-orange {
  background: #fed3c9;
}
.row-orange .action-icons .kebab-icon {
  padding: 6px;
  background: #ffffff;
}
.row-orange .action-icons .checklist-approvers {
  fill: none;
}
.row-orange .action-icons .checklist-approvers path {
  fill: #fc5027;
}
.row-orange .action-icons svg {
  fill: white;
}
.row-disabled {
  background: #f5f5f5;
}
.row-disabled .action-icons .info-icon path,
.row-disabled .action-icons svg path {
  fill: #fff;
}
.row-disabled .action-icons .kebab-icon {
  padding: 6px;
  background: white;
}
.row-disabled .action-icons .kebab-icon svg path {
  fill: #202a3f;
}
.row-disabled .action-icons .checklist-approvers {
  fill: none;
}
.row-disabled .action-icons .checklist-approvers path {
  fill: #fc5027;
}
.row-disabled .action-icons svg {
  fill: #e8e8e8;
}
.row-disabled .action-icons svg path {
  fill: #d9d9d9;
}
.tenant-select-row {
  background: #fefefe;
  padding: 0px 32px;
  min-height: 56px;
}
.tenant-select-row .filter-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #e5e8ed;
  border-radius: 40px;
  padding-left: 8px;
}
.tenant-select-row .filter-wrapper .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: none;
  padding: 0;
  background: transparent;
}
.tenant-select-row .filter-wrapper .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  line-height: 34px;
}
.required-mark {
  color: #f44336;
  font-family: 'Simsun';
  margin-right: 4px;
}
.text-secondary {
  color: #676c77;
}
.text-color {
  color: var(--ant-color-text);
}
.text-danger {
  color: #f44336;
}
.text-success {
  color: #52c41a;
}
.text-warning {
  color: #ffc107;
}
.white-space-pre-line {
  white-space: pre-line;
}
.fw-normal {
  font-weight: 400;
}
.fw-medium {
  font-weight: 500;
}
.fw-semi-bold {
  font-weight: 600;
}
.text-underline {
  text-decoration: underline;
}
.filter-div .ant-select-selection-item {
  line-height: normal;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.bottom-0 {
  bottom: 0;
}
.top-0 {
  top: 0;
}
.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}
.overflow-auto {
  overflow: auto;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.opacity-0 {
  opacity: 0;
}
.infinite-loader {
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.8);
}
.infinite-data-container {
  top: 0;
}
.infinite-data-container .ant-empty {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.mobile-card-wrapper:not(:last-of-type) {
  margin-bottom: 20px;
}
@media screen and (min-width: 1000px) {
  .ant-card-bordered:not(
      .comment-card,
      .version-log-card,
      .approvers-card,
      .notification-card,
      .plan-vs-actual-card,
      .instruction-tab-details-summary,
      .activity-card,
      .response-card,
      .response-detail-card
    ):not(.bordered) {
    border: none;
  }
}
@media screen and (max-width: 999px) {
  .closing-details {
    order: 2;
  }
  .mobile-card {
    border: 1px solid #e5e8ed;
    padding: 16px;
    border-radius: 16px;
  }
  .mobile-card:not(:last-of-type) {
    margin-bottom: 25px;
  }
  .ant-card-bordered {
    border: 1px solid #e8e8e8;
  }
  .ant-card .ant-card-body {
    padding: 16px;
  }
  .ant-card-bordered:not(:last-of-type) {
    margin-bottom: 16px;
  }
  .ant-btn {
    border: none;
  }
  .mobile-card-wrapper {
    padding: 0 16px 16px;
  }
  .mobile-card-wrapper:not(:last-of-type) {
    padding: 0 16px 0px;
  }
  .map-input {
    width: 230px;
  }
}
.ant-form-item-has-error .ant-input-number.ant-input-number-disabled,
.ant-form-item-has-error .ant-picker.ant-input-number-disabled {
  background-color: #f5f5f5;
}
.ant-form-item-has-error .ant-input-number.ant-input-number-disabled:hover,
.ant-form-item-has-error .ant-picker.ant-input-number-disabled:hover {
  background-color: #f5f5f5;
}
.common-scroll-wrapper {
  overflow-y: auto;
  height: calc(100vh - 276px);
  min-height: 400px;
}
.ant-table-content {
  overflow-y: auto;
}
.ant-btn-icon-only {
  box-shadow: none;
}
.ant-form-item .ant-form-item-label > label {
  width: 100%;
  color: #676c77;
  white-space: nowrap;
}
.image-preview-wrapper {
  gap: 0px !important;
}
@media screen and (min-width: 999px) and (max-width: 1350px) {
  .image-preview-wrapper {
    display: flex;
    flex-direction: column;
  }
}
.image-preview-wrapper.issue-images {
  flex-direction: row;
}
.image-preview-wrapper.issue-images .extra-count {
  margin: 0 0 0 5px;
}
.image-preview-wrapper .ant-image-img {
  border-radius: 4px;
}
.image-preview-wrapper div:not(:first-child).ant-space-item {
  margin-right: 0 !important;
}
.image-preview-wrapper div:not(:first-child) .ant-image {
  display: none;
}
.card-header:not(.no-actions) {
  padding-right: 35px;
}
.zoom-controls button.ant-btn {
  border-radius: 50%;
  background-color: #f2f4f7;
  padding-top: 8px;
}
.whitespace-wrap {
  white-space: normal;
}
.grace-bar {
  align-items: center;
  display: flex;
  padding: 10px;
  background-color: crimson;
  justify-content: center;
}
.onsite-bar {
  align-items: center;
  display: flex;
  padding: 10px;
  background-color: #fc5027;
  justify-content: center;
}
.grace-bar-text {
  align-self: center;
  color: white;
  text-align: center;
}
.ant-btn[disabled].comment-button {
  color: white;
  background: rgba(166, 170, 178);
  border: none;
}
.ant-btn[disabled].comment-button:hover {
  color: white;
  background: rgba(166, 170, 178);
  border: none;
}
.has-select-all .ant-select-selector {
  max-height: 200px;
  overflow: auto;
}
.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 24px 0 15px;
}
.ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
  margin: 0 4px 0 0;
}
.ant-modal-content .ant-modal-title {
  color: #202a3f;
}
.ant-modal-content .ant-modal-body {
  color: #676c77;
}
.recommendation-collapse {
  background: transparent;
}
.recommendation-collapse .ant-collapse-header {
  width: fit-content;
}
.recommendation-collapse .ant-collapse-item {
  border: none;
}
.recommendation-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0;
  margin-bottom: 5px;
}
.recommendation-collapse .panel-header {
  font-weight: 500;
  width: fit-content;
  color: #676c77;
  padding: 8px 16px 8px 16px;
  border-radius: 40px;
  background: #f2f4f7;
}
.recommendation-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.recommendation-collapse .previous-details-card-wrapper {
  padding: 16px;
  border: 1px solid #e5e8ed;
  border-radius: 16px;
}
.audio-player-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}
@media screen and (min-width: 999px) and (max-width: 1350px) {
  .audio-player-wrapper {
    display: flex;
    flex-direction: column;
  }
}
.extra-count {
  margin-left: 5px;
  background: #e8e8e8;
  border-radius: 6px;
  padding: 6px;
  font-weight: 400;
}
@media screen and (min-width: 999px) and (max-width: 1350px) {
  .extra-count {
    margin: 5px 0px 0px 0px;
  }
}
.audio-player {
  height: 30px;
  width: 100%;
}
.expand-text.ant-typography {
  margin-bottom: 0;
}
.ant-typography,
.ant-typography-ellipsis,
.expand-text {
  white-space: pre-wrap;
  word-break: break-word;
}
.wrapper-404 {
  min-height: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper-404 .error-img {
  margin-bottom: 30px;
}
.wrapper-404 .error-msg {
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 6px;
}
.wrapper-404 .home-link {
  font-size: 24px;
}
.wrapper-network-offline {
  position: absolute;
  z-index: 500;
  height: calc(100vh - 72px);
  width: 100%;
  background-color: #f2f4f7;
}
.wrapper-network-offline .offline-card {
  height: calc(100% - 40px);
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-network-offline .offline-msg {
  font-weight: 400;
  font-size: 24px;
  margin-top: 30px;
}
@media screen and (max-width: 999px) {
  .wrapper-404 {
    min-height: calc(100vh - 50px - 72px - 40px);
    min-width: auto;
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .wrapper-404 .error-msg {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 6px;
  }
  .wrapper-404 .home-link {
    font-size: 20px;
  }
  .wrapper-network-offline .offline-msg {
    font-size: 20px;
  }
}
button.ant-btn.ant-btn-primary:focus,
button.ant-btn.ant-btn-primary:hover,
a.ant-btn.ant-btn-link:focus,
a.ant-btn.ant-btn-link:hover {
  background: #ff784f;
  border-color: #ff784f;
}
button.ant-btn-default:not(:disabled).cancel-button:focus,
button.ant-btn-default:not(:disabled).cancel-button:hover,
button.ant-btn.previous-comment:focus,
button.ant-btn.previous-comment:hover {
  background: #e3e3e3;
  border-color: #e3e3e3;
}
a:focus {
  text-decoration: underline;
}
.ant-menu a,
.ant-dropdown-menu a,
.ant-menu a:focus,
.ant-dropdown-menu a:focus {
  text-decoration: none;
  background-color: transparent;
}
button.ant-btn-icon-only:focus {
  box-shadow: 0 0 3px 1px transparent;
}
.filter-btn.ant-btn-default:not(:disabled):not(.ant-btn-disabled) {
  border: none;
  padding: 0;
  background-color: #202a3f;
  height: 30px;
  min-width: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter-btn.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  background: #202a3f;
}
.collapsible-filter-wrapper .ant-collapse,
.collapsible-filter-wrapper .ant-collapse-item,
.collapsible-filter-wrapper .ant-collapse-content {
  border: none;
}
.collapsible-filter-wrapper .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: none;
}
.collapsible-filter-wrapper .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.collapsible-filter-wrapper .ant-col:nth-of-type(2n) {
  padding: 0;
}
.collapsible-filter-wrapper .ant-col:nth-of-type(2n + 1) {
  padding-left: 0;
}
.common-dropdown-wrapper .ant-select.ant-select-single .ant-select-selector {
  border-radius: 40px;
}
.common-dropdown-wrapper .ant-spin-blur {
  opacity: 1;
}
.common-dropdown-wrapper .ant-select-selection-item-remove .anticon {
  font-size: unset;
}
.common-dropdown-wrapper .tags-dropdown .ant-select-selector {
  border-radius: 40px;
}
.common-dropdown-wrapper .blinking-dropdown-border.ant-select.ant-select-single .ant-select-selector {
  border: 2px solid #fc5027;
  animation-name: blinking;
  animation-duration: 1s;
  animation-iteration-count: 5;
}
@keyframes blinking {
  50% {
    border-color: #ffffff;
  }
}
.ant-input,
.ant-input-affix-wrapper,
.common-dropdown-wrapper .ant-select .ant-select-selector {
  border-color: #e5e8ed;
}
#google-maps-container > .pac-container {
  top: 48px !important;
  left: 10px !important;
}
.dropdown-width-auto .ant-select-dropdown {
  max-width: 200px;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  display: none;
}
.ant-table-thead > tr > th::before {
  display: none;
}
.ant-input-clear-icon {
  line-height: 0;
}
.alert-div {
  background-color: #fff1f0;
  display: flex;
  justify-content: center;
  padding: 5px 0px;
}
.wrong-icon {
  fill: #cf1322;
}
.disabled-button svg path {
  fill: #676c77;
}
.eqc-text {
  max-width: 200px;
}
.h1-typography {
  color: #202a3f;
  font-weight: 500;
  line-height: 1.2;
  font-size: 22px;
}
.h2-typography {
  color: #202a3f;
  font-weight: 500;
  line-height: 1.2;
  font-size: 20px;
}
.ant-dropdown {
  z-index: 999;
}
.ant-tooltip-inner {
  max-height: calc(100vh - 150px);
  overflow: auto;
}
.ant-tooltip {
  z-index: 1000 !important;
}
.no-background-and-border .ant-btn-round.ant-btn-icon-only {
  border: none;
  background: unset;
}
.contact-action-button button.ant-btn.ant-btn-round {
  margin-right: 10px;
}
.image-count {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #676c77;
}
.data-count {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #676c77;
}
.gallery-info .ant-popover-arrow {
  display: none;
}
.gallery-image-popover .ant-popover-content {
  max-width: 310px;
  height: auto;
}
.gallery-image-popover .ant-popover-content .ant-popover-arrow {
  display: none;
}
.gallery-image-popover .ant-popover-content .ant-popover-inner {
  background: #f2f4f7;
}
.gallery-image-popover .ant-popover-content .ant-popover-inner .ant-descriptions-item-label,
.gallery-image-popover .ant-popover-content .ant-popover-inner .ant-descriptions-item-content {
  font-size: 16px;
}
.global-agency-info .ant-popover-arrow {
  display: none;
}
.global-agency-info.ant-popover {
  max-width: none;
  width: 300px;
}
.no-tag-render .ant-select-selection-overflow-item .ant-select-selection-item {
  display: none;
}
.no-tag-render .ant-select-selector .ant-select-selection-item {
  display: none;
}
.ant-select-item-option {
  font-size: 14px;
}
.readonly-select {
  border: 1px solid #e5e8ed;
  border-radius: 40px;
  padding: 6px 8px;
}
.readonly-select .anticon svg {
  fill: rgba(0, 0, 0, 0.25);
  width: 12px;
}
.readonly-select-with-glow {
  border-color: #fc5027;
  box-shadow: 0 0 0 2px rgba(252, 80, 39, 0.2);
}
.readonly-selected-with-glow {
  border-color: var(--ant-color-text);
  box-shadow: 0 0 0 2px rgba(21, 18, 89, 0.2);
}
.cursor-not-allowed .readonly-select {
  background: #f5f5f5;
}
.editable-table .ant-table .ant-table-tbody .anticon {
  font-size: unset;
}
.editable-table .contact-name {
  max-width: 200px;
}
.editable-table .contact-name-with-count {
  max-width: 150px;
}
.editable-table .eqc-type-contact-name {
  max-width: 150px;
}
.editable-table .eqc-type-contact-name-with-count {
  max-width: 80px;
}
.editable-table .contact-count {
  width: max-content;
}
.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
}
.placeholder-text {
  color: #bfbfbf;
}
.add-project-eqc-type-table .ant-table-tbody > tr > td {
  max-width: 200px;
}
.add-project-agency-table .ant-table-tbody > tr > td {
  max-width: 300px;
}
.add-approver-modal .title {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #202a3f;
}
.add-approver-modal .description {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #b3b6c3;
}
.add-approver-modal .ant-modal-body {
  padding: 4px;
}
.add-approver-modal .heading {
  padding: 20px 20px 0;
}
.add-approver-modal .add-approval-form {
  max-height: calc(100vh - 400px);
  overflow: auto;
  overflow-x: hidden;
  padding: 0 16px 0 16px;
}
.add-approver-modal .add-approval-form .error-text {
  margin-top: -20px;
}
.add-approver-modal .add-approval-form .approvalType-select .ant-select-selector {
  border-radius: 8px;
}
.add-approver-modal .hide-overflow {
  overflow: hidden;
}
.setup-module.ant-card-bordered {
  border: none;
}
@media screen and (max-width: 999px) {
  .setup-module .mobile-card-wrapper {
    padding-top: 16px;
  }
  .setup-module .mobile-card-wrapper .page-header {
    padding: 16px 16px 0;
  }
  .setup-module .setup-module > .ant-card-body {
    padding: 0;
  }
}
.setup-module.project-details .ant-tabs-tabpane {
  padding: 0;
}
.setup-module .user-activity-count .title {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #676c77;
}
.setup-module .user-activity-count .title .count {
  color: var(--ant-color-text);
}
.setup-module .user-status-time {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  color: var(--ant-color-text);
}
.setup-module .down-arrow {
  transform: scale(1.3);
}
.setup-module .user-count svg path {
  fill: #535353;
}
.setup-module .count-popover {
  width: auto;
}
.setup-module .count-popover .ant-popover-inner-content ul {
  padding: 0 0 0 20px;
  margin: 0;
}
.name-and-time {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #676c77;
}
.name-and-time .ellipsis-text {
  max-width: 200px;
}
.checklist-changelog-popup.ant-popover {
  overflow: unset;
}
#view-approvers-drawer,
.checklist-changelog-popup,
.checklist-approvers-popup {
  max-width: 480px;
  width: fit-content;
  overflow: auto;
  overflow-x: hidden;
}
#view-approvers-drawer .ant-descriptions-view table,
.checklist-changelog-popup .ant-descriptions-view table,
.checklist-approvers-popup .ant-descriptions-view table {
  table-layout: fixed;
}
#view-approvers-drawer .ant-descriptions-row > th,
.checklist-changelog-popup .ant-descriptions-row > th,
.checklist-approvers-popup .ant-descriptions-row > th,
#view-approvers-drawer .ant-descriptions-row > td,
.checklist-changelog-popup .ant-descriptions-row > td,
.checklist-approvers-popup .ant-descriptions-row > td {
  padding: 0;
}
#view-approvers-drawer .outer .ant-descriptions-item-label,
.checklist-changelog-popup .outer .ant-descriptions-item-label,
.checklist-approvers-popup .outer .ant-descriptions-item-label {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-top: 14px;
}
#view-approvers-drawer .inner .ant-descriptions-item-label,
.checklist-changelog-popup .inner .ant-descriptions-item-label,
.checklist-approvers-popup .inner .ant-descriptions-item-label {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-top: 10px;
}
#view-approvers-drawer .inner .ant-descriptions-item-content,
.checklist-changelog-popup .inner .ant-descriptions-item-content,
.checklist-approvers-popup .inner .ant-descriptions-item-content {
  color: #bfbfbf;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
#view-approvers-drawer .ant-popover-title,
.checklist-changelog-popup .ant-popover-title,
.checklist-approvers-popup .ant-popover-title {
  border: none;
  color: #cdd5e0;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 10px 10px 0;
}
#view-approvers-drawer .ant-popover-arrow .ant-popover-arrow-content,
.checklist-changelog-popup .ant-popover-arrow .ant-popover-arrow-content,
.checklist-approvers-popup .ant-popover-arrow .ant-popover-arrow-content {
  --antd-arrow-background-color: #101326;
}
#view-approvers-drawer .ant-popover-inner,
.checklist-changelog-popup .ant-popover-inner,
.checklist-approvers-popup .ant-popover-inner {
  border-radius: 8px;
  background: #101326;
  box-shadow: 0px 25px 50px 0px rgba(81, 89, 131, 0.25);
  padding: 0;
}
#view-approvers-drawer .ant-popover-inner-content,
.checklist-changelog-popup .ant-popover-inner-content,
.checklist-approvers-popup .ant-popover-inner-content {
  padding: 0 10px 10px;
  color: #ffffff;
  max-height: calc(100vh - 300px);
  overflow: auto;
}
#view-approvers-drawer .info-icon,
.checklist-changelog-popup .info-icon,
.checklist-approvers-popup .info-icon {
  transform: rotate(180deg);
}
#view-approvers-drawer ul,
.checklist-changelog-popup ul,
.checklist-approvers-popup ul {
  padding: 0 0 0 24px;
  margin-bottom: 0;
}
#view-approvers-drawer ul li,
.checklist-changelog-popup ul li,
.checklist-approvers-popup ul li {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
#view-approvers-drawer .ant-drawer-content-wrapper {
  width: 100% !important;
}
#view-approvers-drawer .ant-drawer-content {
  border-radius: 12px 12px 0px 0px;
  background: #101326;
  box-shadow: 0px 25px 50px 0px rgba(81, 89, 131, 0.25);
  color: #ffffff;
  padding: 16px;
}
#view-approvers-drawer .title {
  color: #cdd5e0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
#view-approvers-drawer .ant-divider {
  border-top: 1px solid #676c77;
}
#view-approvers-drawer .ant-divider-horizontal {
  margin: 12px 0 0;
}
#view-approvers-drawer .ant-drawer-body {
  padding: 0;
}
.note-margin {
  margin: -10px 0 10px 0;
}
.tags-div {
  max-width: 150px;
}
.react-flow__attribution a {
  display: none;
}
.react-flow__controls-button {
  height: 22px;
  width: 22px;
}
.tree-structure {
  width: 100%;
  overflow: auto;
  display: grid;
  flex-direction: column;
}
.tree-structure.inside-modal {
  height: auto;
  max-height: calc(100vh - 296px);
  border: 1px solid #e5e8ed;
}
.tree-structure .app {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}
.tree-structure .current {
  background-color: #f3f3f3;
  margin: 8px;
  padding: 8px;
}
.tree-structure .currentLabel {
  font-weight: bold;
  margin-inline-start: 0.5em;
}
.tree-structure .draggingSource {
  opacity: 0.3;
}
.tree-structure .dropTarget {
  background-color: #e8f0fe;
}
.tree-structure .node-action {
  display: none;
}
.tree-structure .node-action .add-button {
  transform: scale(0.8);
}
.tree-structure .node-action .add-button path {
  fill: #000;
}
.tree-structure .custom-node {
  width: max-content;
  min-width: 100%;
  height: 36px;
  border-bottom: 1px solid #e5e8ed;
}
.tree-structure .custom-node:hover .node-action {
  display: flex;
  align-items: center;
}
.tree-structure .custom-node .expandIconWrapper {
  align-items: center;
  cursor: pointer;
  font-size: 10px;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  transition: transform linear 0.1s;
  transform: rotate(0deg);
}
.tree-structure .custom-node .expandIconWrapper button {
  height: 24px;
}
.tree-structure .custom-node .expandIconWrapper .anticon {
  height: 10px;
  width: 10px;
}
.tree-structure .custom-node .expandIconWrapper.isOpen {
  transform: rotate(90deg);
}
.tree-structure .ant-tree {
  flex: 1;
}
.tree-structure .ant-tree .ant-tree-treenode {
  border-bottom: 1px solid #e5e8ed;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding: 5px;
}
.tree-structure .site-tree-search-value {
  background-color: #00ff00;
}
.tree-structure .ant-tree .ant-tree-node-content-wrapper {
  width: 100%;
}
.tree-structure .ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #ffdbc9;
}
.tree-structure .ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}
.tree-structure .l0-tree-title {
  color: #fda793;
}
.tree-structure .l1-tree-title {
  color: #1890ff;
}
.tree-structure .l2-tree-title {
  color: #f5222d;
}
.tree-structure .l3-tree-title {
  color: #fa8c16;
}
.tree-structure .l4-tree-title {
  color: #722ed1;
}
.tree-structure .l5-tree-title {
  color: #52c41a;
}
.tree-structure .l6-tree-title {
  color: #13c2c2;
}
.tree-structure .l7-tree-title {
  color: #2f54eb;
}
.tree-structure .l8-tree-title {
  color: #eb2f96;
}
.tree-structure .l9-tree-title {
  color: #b3b6c3;
}
.tree-structure .disabled-tree-title {
  color: #b3b6c3;
}
.tree-structure .disabled-tree-title .level-name {
  text-decoration: line-through;
  text-decoration-color: #000000;
}
.thin-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}
.thin-scrollbar::-ms-scrollbar {
  width: 5px;
  height: 5px;
}
.thin-scrollbar::-ms-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}
.sticky-action-buttons {
  display: flex;
  flex-direction: column;
  width: 90px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: sticky;
  bottom: 0;
  border: 1px solid #e5e8ed;
  background: #f5f5f5;
  box-shadow: 0px -2px 4px 0px rgba(62, 62, 62, 0.1);
}
.sticky-action-buttons div {
  cursor: pointer;
  text-align: center;
}
.sticky-action-buttons div.cursor-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.sticky-action-buttons div.cursor-disabled .button-title {
  cursor: not-allowed;
}
.sticky-action-buttons div:not(:last-child) {
  margin-bottom: 12px;
}
.sticky-action-buttons div .button-title {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: #535353;
}
.sticky-action-buttons .enable-icon path {
  fill: var(--ant-color-text);
}
.pass-fail-count {
  max-width: 200px;
}
.pass-fail-count .ant-popover-inner-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #535353;
}
.pass-fail-count .ant-popover-inner-content .count {
  color: var(--ant-color-text);
}
#tabs-footer .nomenclature-extra-info {
  padding: 5px;
  background-color: #e5e8ed;
  width: fit-content;
}
@media screen and (max-width: 999px) {
  #tabs-footer .nomenclature-extra-info {
    margin: -16px 16px 16px;
  }
}
.nomenclature-mobile-info .ant-message-notice-content {
  padding: 8px 6px;
  background-color: #fff7e6;
  border: 1px solid #fa8c16;
}
.nomenclature-mobile-info .ant-message-notice-content svg path {
  fill: #000000;
}
#upgrade-modal .duplicate-icon {
  cursor: pointer;
}
#upgrade-modal .duplicate-icon g path {
  fill: #fc5027;
}
#upgrade-modal .ant-modal-mask {
  z-index: 1001;
}
#upgrade-modal .ant-modal-wrap {
  z-index: 9999;
}
#upgrade-modal p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #535353;
  margin-bottom: 10px;
}
#upgrade-modal .ant-modal-body .form-buttons {
  padding: 0;
}
#upgrade-modal .ant-modal-body .form-buttons .ant-btn {
  margin: 0;
}
#upgrade-modal .ant-modal-body .form-buttons .ant-btn span {
  margin: 0;
}
#upgrade-modal .ant-modal-close-x svg {
  fill: var(--ant-color-text);
}
#upgrade-modal .ant-modal-close:hover {
  background-color: unset;
}
.tags-col .info-icon path {
  fill: #535353;
}
.organization-details .info-icon path {
  fill: #535353;
}
.plan-info-content h5 {
  margin: 0;
}
.date-header-popover h5 {
  margin: 0;
}
.double-tick-icon {
  fill: #065f46;
}
.country-code-phone-input .ant-input-group-addon:first-child {
  padding: 0;
}
.country-code-phone-input .country-code-input {
  width: 80px;
}
.country-code-phone-input .country-code-input.ant-form-item {
  margin-bottom: 0;
}
.country-code-phone-input .country-code-input .ant-form-item-control-input {
  min-height: 30px;
}
.country-code-phone-input .country-code-input .ant-select.ant-select-single {
  width: 100%;
}
.country-code-phone-input .country-code-input .ant-select-selector {
  padding: 0;
}
.custom-date-picker-panel .ant-picker-panel-layout {
  flex-direction: column-reverse;
}
@media screen and (max-width: 999px) {
  .custom-date-picker-panel .ant-picker-panel-layout {
    max-width: 288px;
  }
}
@media screen and (max-width: 999px) {
  .custom-date-picker-panel .ant-picker-panel-layout .ant-picker-panels .ant-picker-panel:nth-of-type(1) button {
    visibility: visible !important;
  }
  .custom-date-picker-panel .ant-picker-panel-layout .ant-picker-panels .ant-picker-panel:nth-of-type(2) .ant-picker-header button {
    visibility: hidden;
  }
}
.custom-date-picker-panel .ant-picker-panel-layout .ant-picker-presets {
  max-width: 100%;
}
.custom-date-picker-panel .ant-picker-panel-layout .ant-picker-presets ul {
  height: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-right: none;
  border-top: var(--ant-line-width) var(--ant-line-type) var(--ant-color-split);
  gap: 16px;
}
.custom-date-picker-panel .ant-picker-panel-layout .ant-picker-presets ul li {
  font-size: 12px;
}
.custom-date-picker-panel .ant-picker-panel-layout .ant-picker-presets ul li + li {
  margin: 0;
}
@media screen and (max-width: 999px) {
  .custom-date-picker-panel .ant-picker-panel:last-child {
    width: 0;
  }
  .custom-date-picker-panel .ant-picker-panel:last-child .ant-picker-header {
    position: absolute;
    right: 0;
  }
  .custom-date-picker-panel .ant-picker-panel:last-child .ant-picker-header .ant-picker-header-prev-btn,
  .custom-date-picker-panel .ant-picker-panel:last-child .ant-picker-header .ant-picker-header-view {
    visibility: hidden;
  }
  .custom-date-picker-panel .ant-picker-panel:last-child .ant-picker-body {
    display: none;
  }
}
.timezone-div {
  font-size: 10px;
  color: #676c77;
  font-weight: 400;
}
.timezone-div svg {
  height: 16px;
  width: 16px;
  min-width: unset;
  margin-right: 5px;
}
.timezone-div svg g {
  fill: #676c77;
}
.mobile-layout {
  flex: 1 1;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  align-self: auto;
}
[hidden] {
  display: none;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  padding: 0;
}
.ant-pagination li:not(.ant-pagination-options) {
  border: 1px solid #d9d9d9;
}
.ant-pagination li.ant-pagination-item-active {
  border-color: #fc5027;
}
::selection {
  background: #fc5027;
  color: white;
}
.ant-picker-dropdown.ant-picker-dropdown-placement-bottomRight .ant-picker-range-arrow {
  display: none;
}
.danger-button {
  color: #fff;
  border-color: #f5222d;
  background: #f5222d;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-table-placeholder {
  z-index: 999 !important;
}
.ant-btn:not(:disabled):focus-visible {
  outline: none;
  outline-offset: unset;
}
@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.on-site-approval-bar {
  position: fixed;
  width: 100%;
  z-index: 9;
}
@media screen and (min-width: 999px) {
  .on-site-approval-bar {
    margin: -15px -20px 10px -20px;
  }
}
.blinking-banner {
  animation: blink 1s infinite;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev,
.ant-pagination.ant-pagination-mini .ant-pagination-next {
  margin: 0 8px;
}
.ant-btn.ant-btn-icon-only.ant-btn-round {
  width: 32px;
}
.ant-btn-default .ant-wave {
  display: none;
}
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #fc5027;
}
.width-max-content {
  width: max-content;
}
#router-prompt .ant-modal {
  z-index: 9999;
}
.ant-btn .ant-btn-icon {
  line-height: 0;
}
.ant-modal .ant-modal-close:hover {
  background-color: unset;
}
#sub-header h5 {
  color: #676c77;
  font-weight: 400;
}
.user-info-popover {
  max-width: 360px;
}
.user-info-popover .ant-popover-inner {
  max-width: 316px;
}
.user-info-popover .ant-popover-inner-content {
  padding: 8px;
}
.user-info-popover .ant-popover-arrow {
  display: none;
}
.user-info-popover .user-info-div {
  max-width: 300px;
  padding: 8px 12px 8px 10px;
  border-radius: 8px;
  background: #f2f4f7;
  border: 1px solid #e5e8ed;
}
.user-info-popover .user-info-div svg circle {
  fill: #ffffff;
}
.action-drawer {
  padding: 4px;
  background-color: #535353;
  border-radius: 10px;
  opacity: 0;
  /* Default: hidden */
  transform: translateY(20px);
  /* Default: hidden */
  pointer-events: none;
  /* Prevent interactions when hidden */
  transition: opacity 0.4s ease, transform 0.4s ease;
  /* Smooth transitions */
}
.action-drawer.visible {
  opacity: 1;
  /* Fully visible */
  transform: translateY(0);
  /* Final position */
  pointer-events: auto;
  /* Enable interactions */
}
.action-drawer .ant-btn.ant-btn-round.ant-btn,
.action-drawer .ant-select-single {
  height: 30px;
}
.action-drawer .ant-select-single {
  width: 190px;
}
.fade-in-from-bottom {
  animation: fadeInBottom 0.4s ease-out forwards;
}
@keyframes fadeInBottom {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fade-out-to-bottom {
  animation: fadeOutBottom 0.4s ease-out forwards;
}
@keyframes fadeOutBottom {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}
.uploaded-images {
  max-height: 130px;
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
}
.uploaded-images .ant-image .ant-image-img {
  padding: 8px;
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 6px;
}
.upload-button {
  color: #676c77;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid #d9d9d9;
}
.ant-upload {
  width: 100%;
}
.response-images {
  position: relative;
  height: 65px;
  width: 65px;
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin: 0 5px 5px 0;
}
.response-images:hover .delete-response-image-button {
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
}
.response-images .ant-image-mask {
  display: none;
}
.response-images .ant-image {
  width: 100%;
}
.delete-response-image-button {
  width: calc(100% - 16px);
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: calc(100% - 16px);
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  opacity: 0;
  transition: opacity 0.35s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}
.delete-response-image-button svg {
  fill: #ffffff;
}
.ant-form input[type='file'] {
  display: none;
  color: transparent;
}
.ant-form label.file-upload {
  font-size: 16px;
}
.file-upload {
  border: 1px solid #d9d9d9;
  border-radius: 36px;
  padding: 7.9px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #676c77;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.file-upload.disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.response-detail-card {
  border: 1px solid #e8e8e8;
}
.issue-heading {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  max-width: 50%;
}
.indent-1 {
  padding: 0 22px;
}
.indent-2 {
  padding: 0 44px;
}
.indent-3 {
  padding: 0 66px;
}
.indent-4 {
  padding: 0 88px;
}
.indent-5 {
  padding: 0 110px;
}
.indent-6 {
  padding: 0 132px;
}
.indent-7 {
  padding: 0 154px;
}
.indent-8 {
  padding: 0 176px;
}
.indent-9 {
  padding: 0 198px;
}
.dragging {
  background: #ffdbc9;
}
.drop-target {
  background: rgba(0, 128, 255, 0.2);
}
.drag-handle {
  cursor: grab;
}
.drag-handle:active {
  cursor: grabbing;
}
